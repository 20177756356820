import './FloorMap.css'

import React, { useContext, useEffect, useState } from 'react'
import { API, DataStore } from 'aws-amplify'
import { View, Image, Heading, Text, Divider } from '@aws-amplify/ui-react'
import { v4 as uuid } from 'uuid'
import { Profile, BeerActionFrame, BeerActionModal, FloorMapSvg } from '../../components'
import { AllowFloor, Person } from '../../models'
import { getLogo } from '../../lib'
import { useCookies } from 'react-cookie'
import { ConfigContext, InProgressContext } from '../../contexts'
import { FloorMapDataModel, PositionDataModel, UserIconDataModel } from '../../types'
import { AdminModal } from '../../components/AdminModal'
import { FloorMapMenu } from '../../components/FloorMapMenu'

interface Props {
  person?: Person | null
  persons?: Person[]
  setShowLogoutModal: React.Dispatch<React.SetStateAction<boolean>>
}

interface AssetPositions {
  asset_positions: PositionDataModel[]
}

const apiname = process.env.REACT_APP_HACCKE_API
if (typeof apiname === 'undefined') {
  throw new Error('apiname is undefined')
}

/**
 * フロアマップ画面
 */
export const FloorMap = ({ person, persons, setShowLogoutModal }: Props): JSX.Element => {
  const [cookies, setCookie] = useCookies(['floor_id'])

  const configContext = useContext(ConfigContext)
  const setInProgress = useContext(InProgressContext)

  const [pageId] = useState(uuid())
  const [backgroundId] = useState(uuid())
  const [showMenu, setShowMenu] = useState(false)
  const [showAdminModal, setShowAdminModal] = useState(false)
  const [title, setTitle] = useState<string | null>()
  const [selectedPerson, setSelectedPerson] = useState<Person>()
  const [showProfile, setShowProfile] = useState(false)
  const [profileRight, setProfileRight] = useState(false)
  const [positions, setPositions] = useState<PositionDataModel[]>()
  const [userIcons, setUserIcons] = useState<UserIconDataModel[]>()
  const [corporateLogo, setCorporateLogo] = useState<Blob>()
  const [showBeerActionModal, setShowBeerActionModal] = useState(false)
  const [getTogether, setGetTogether] = useState(false)

  const [floormaps, setFloormaps] = useState<FloorMapDataModel[]>()
  const [selectedFloor, setSelectedFloor] = useState<FloorMapDataModel>()

  /**
   * フロアリストと許可リストを取得し、許可されているフロアマップを設定する
   */
  const fetchFloormaps = (): void => {
    console.debug('fetch Floormaps')
    const path = '/floors'
    if (floormaps != null) {
      return
    }
    Promise.all([
      API.get(apiname, path, {}),
      DataStore.query(AllowFloor)
    ])
      .then(([result, allows]) => {
        const floors: FloorMapDataModel[] = result.floors.map((m: unknown) => m as FloorMapDataModel)
        const allowIds = allows.map(f => parseInt(f.allowFloorId ?? '-1'))
        return floors.filter(m => allowIds.includes(m.id))
      })
      .then(setFloormaps)
      .catch(console.error)
  }

  /**
   * 企業ロゴ取得
   */
  const fetchLogo = (): void => {
    getLogo().then(setCorporateLogo).catch(console.error)
  }

  /**
   * アイコン位置情報取得
   */
  const fetchPositions = (selectedFloor: FloorMapDataModel): void => {
    const path = `/floors/${selectedFloor.id}/latest_asset_positions`
    API.get(apiname, path, {})
      .then((response: AssetPositions) => setPositions(response.asset_positions))
      .catch(console.error)
  }

  useEffect(() => {
    if (positions == null) {
      return
    }
    console.debug('effect positions: ', positions)
  }, [positions])

  /** 表示用パーソンデータモデルを生成 */
  useEffect(() => {
    if (persons == null || positions == null) {
      return
    }
    const userIcons: UserIconDataModel[] = positions
      .map(position => {
        return {
          person: persons.find(p => p.assetCode === position.asset_code),
          position: {
            x: position.x,
            y: position.y
          }
        }
      })
      .filter(p => p.person != null)
      .map(p => p as unknown as UserIconDataModel)
    setUserIcons(userIcons)
  }, [persons, positions])

  /**
   * ロード
   */
  useEffect(() => {
    fetchFloormaps()
    fetchLogo()
    // setInProgress?.(false)
  }, [])

  /**
   * ビール、判定
   */
  useEffect(() => {
    if (persons == null || configContext == null) {
      return
    }
    const party = persons.filter(p => p.getTogether === true)
    const tally = party.length >= configContext.beerCount
    const value = configContext.beer && tally
    setGetTogether(value)
  }, [persons, configContext])

  /**
   * 初期フロア選択
   */
  useEffect(() => {
    if (floormaps == null) {
      return
    }
    console.debug('effect floormaps: ', floormaps)
    if (floormaps.length === 0) {
      setInProgress?.(false)
      return
    }
    if (selectedFloor == null) {
      const selectedFloor = floormaps.find(f => f.id.toString() === cookies.floor_id) ?? floormaps[0]
      //  アイコン位置取得
      fetchPositions(selectedFloor)
      setSelectedFloor(selectedFloor)
    }
  }, [floormaps])

  useEffect(() => {
    if (userIcons == null) {
      return
    }
    console.debug('effect userIcons: ', userIcons)
  }, [userIcons])

  /**
   * フロアが選択されたとき、フロアマップを設定する
   */
  useEffect(() => {
    if (selectedFloor == null || configContext == null) {
      return
    }
    console.debug('effect selectedFloor: ', selectedFloor)

    //  フロア更新？
    // if (cookies.floor_id !== selectedFloor.id.toString()) {
    setInProgress?.(true)
    setCookie('floor_id', selectedFloor.id)
    //  タイトル設定
    setTitle(selectedFloor.name)
    //  アイコン位置取得
    fetchPositions(selectedFloor)
    // }

    const timer = setInterval(() => {
      fetchPositions(selectedFloor)
    }, (configContext.fetchInterval ?? 5) * 1000)

    return () => clearInterval(timer)
  }, [selectedFloor, configContext])

  // null check
  if (setInProgress == null || configContext == null) {
    return <></>
  }

  if (person == null || persons == null) {
    return <></>
  }

  /**
   * onToggleMenu
   */
  const onToggleMenu = (): void => {
    setShowMenu(!showMenu)
    if (showProfile) {
      setShowProfile(false)
    }
  }

  /**
   * 背景クリック
   */
  const onBackgroundClick = (): void => {
    const config = configContext.headerVariation === 'none'
    if (config) {
      if (showMenu) {
        setShowMenu(false)
      } else if (!showProfile) {
        setShowMenu(true)
      }
    }
    setShowProfile(false)
  }

  const isNavy = configContext.headerVariation === 'navy'
  return (
    <View
      id={pageId}
      onClick={(e: React.MouseEvent<Element>) => {
        if ('id' in e.target && e.target.id === backgroundId) {
          onBackgroundClick()
        }
      }}
      className={'floor-map-page' + (configContext.headerVariation === 'none' ? ' floor-map-page-header-none' : '')}
    >
      {
        showBeerActionModal ? <BeerActionModal persons={persons?.filter(p => p.getTogether ?? false)} onClose={() => setShowBeerActionModal(false)} /> : <></>
      }

      {
        // header
        configContext.headerVariation === 'none'
          ? <View></View>
          : <>
            <header className={'floor-map-header' + (isNavy ? ' floor-map-header-navy' : '')}>
              <button className="btn floor-map-header-menu-toggle" type="button" onClick={onToggleMenu}>
                <i className="bi-list floor-map-header-menu-toggle-icon" role="img" aria-label="menu-toggler" />
              </button>

              {/* Service Name */}
              <View className="floor-map-header-service-name">
                <Text fontSize='xl'>{configContext.serviceName ?? 'サービス名が設定されていません'}</Text>
              </View>

              <Divider orientation='vertical' className='floor-map-header-divider' />

              <Heading fontSize={'xl'} className='floor-map-header-floor-name'>{title}</Heading>

              {configContext.showCorporateLogo && corporateLogo != null ? <Image className='floor-map-header-icon' src={URL.createObjectURL(corporateLogo)} alt='rogo'></Image> : <></>}
            </header>
            <View className='floor-map-header-divider-horizontal' />
          </>
      }

      <main className="floor-map-main">
        {showMenu
          ? <FloorMapMenu
            person={person}
            selectedFloor={selectedFloor}
            floormaps={floormaps}
            setSelectedFloor={setSelectedFloor}
            setShowAdminModal={setShowAdminModal}
            setShowBeerActionModal={setShowBeerActionModal}
            setShowLogoutModal={setShowLogoutModal}
          />
          : <></>}
        <View id={backgroundId} className="floor-map-background">
          {
            selectedFloor != null
              ? <FloorMapSvg
                floorId={selectedFloor.id.toString()}
                getTogether={getTogether}
                persons={userIcons}
                showProfile={(person, right) => {
                  setSelectedPerson(person)
                  setProfileRight(right)
                  setShowProfile(true)
                  setShowMenu(false)
                }}
                onClick={() => {
                  onBackgroundClick()
                }}
                onLoad={() => {
                  setInProgress(false)
                }}
              />
              : <></>
          }
        </View>
        {
          showProfile
            ? <View className={'floor-map-profile' + (profileRight ? ' floor-map-profile-right' : '')}>
              <Profile person={selectedPerson} />
            </View>
            : <></>
        }
        {configContext.showMacnicaLogo ? <Image className='floor-map-main-icon' src='/atoms/rogo.svg' alt='rogo'></Image> : <></>}
        {
          configContext.beer
            ? <BeerActionFrame
              person={person}
              onClickDetails={() => setShowBeerActionModal(true)}
              className='floor-map-beer-action-frame' />
            : <></>
        }
      </main>
      <footer></footer>
      {
        person.admin === true
          ? <AdminModal
            persons={persons}
            show={showAdminModal}
            onClose={() => setShowAdminModal(false)}
          />
          : <></>}
    </View>
  )
}
