import './FloorMapSvg.module.css'
import { v4 as uuid } from 'uuid'
import React, { useContext, useEffect, useState } from 'react'
import { UserIconDataModel } from '../types'
import { Person } from '../models'
import { makeUsericon } from './Usericon'
import { ConfigContext } from '../contexts'
import { fetchFloormap } from '../lib'

interface Props {
  floorId: string
  persons?: UserIconDataModel[]
  getTogether: boolean
  className?: string
  showProfile: (person: Person, right: boolean) => void
  onClick: () => void
  onLoad?: () => void
}

export const FloorMapSvg = ({ floorId, persons, getTogether, className, showProfile, onClick, onLoad }: Props): JSX.Element => {
  const configContext = useContext(ConfigContext)
  const [id] = useState(uuid())
  const [parser] = useState(new DOMParser())
  const [floormapOrigin, setFloormapOrigin] = useState<Document>()
  const [usericonOrigin, setUsericonOrigin] = useState<Document>()
  const [personViewModels, setPersonViewModels] = useState<Node[]>()
  const [viewModel, setViewModel] = useState<Document>()

  /** fetch usericon */
  useEffect(() => {
    console.debug('load usericon')
    fetch('/atoms/UserIconDesign.svg')
      .then(async r => await r.text())
      .then(text => parser.parseFromString(text, 'application/xml'))
      .then(setUsericonOrigin)
      .catch(console.error)
  }, [])

  /** fetch floormap */
  useEffect(() => {
    console.debug('effect floorId: ', floorId)
    fetchFloormap(floorId)
      .then(setFloormapOrigin)
      .catch(console.error)
  }, [floorId])

  /** person data models */
  useEffect(() => {
    if (persons == null || usericonOrigin == null || configContext == null) {
      return
    }
    console.debug('effect persons: ', persons)
    console.debug('effect getTogether: ', getTogether)
    Promise.all(persons.map(async person => await makeUsericon({
      origin: usericonOrigin,
      dataModel: person,
      beer: getTogether && (person.person.getTogether ?? false),
      userIconVariation: configContext.userIconVariation,
      showProfile
    })))
      .then(setPersonViewModels)
      .catch(console.error)
  }, [persons, getTogether])

  /** deployment persons */
  useEffect(() => {
    if (personViewModels == null || floormapOrigin == null) {
      return
    }
    const viewModel = floormapOrigin?.cloneNode(true) as Document
    const grid = viewModel.getElementById('__grid__')
    const assets = document.createElement('g')
    assets.setAttribute('id', 'assets')

    while (grid?.firstChild != null) {
      grid?.removeChild(grid?.lastChild as Node)
    }

    personViewModels.forEach(p => grid?.appendChild(p))

    grid?.setAttribute('style', '')
    grid?.setAttribute('display', 'block')
    grid?.appendChild(assets)

    const root = viewModel.documentElement
    root.setAttribute('style', 'max-height: 90vh')

    setViewModel(viewModel)
  }, [floormapOrigin, personViewModels])

  /** update view */
  useEffect(() => {
    const elements = document.getElementById(id)
    if (elements == null) {
      throw new Error()
    }
    while (elements.firstChild != null) {
      elements.removeChild(elements.lastChild as Node)
    }
    const svg = viewModel?.firstChild
    if (svg == null) {
      return
    }
    elements.appendChild(svg)
    onLoad?.()
  }, [viewModel])

  return (
    <div id={id} onClick={onClick} className={`floor-map-svg ${className ?? ''}`} />
  )
}
