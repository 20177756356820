import './ProfileRegistrant.css'

import React, { useContext, useEffect, useState } from 'react'

import { Storage } from 'aws-amplify'
import { Text, Divider, View, Grid, Card, Heading } from '@aws-amplify/ui-react'
import { Crop } from 'react-image-crop'

import { ProfileImageField } from '../../components'
import { PersonCreateForm } from '../../my-ui-components'
import { useNavigate } from 'react-router-dom'
import { PersonValidater } from '../../lib'
import { InProgressContext } from '../../contexts'
import { Person } from '../../models'

interface Props {
  person?: Person | null
  email: string
  onUpload: () => void
}

/**
 * プロフィール登録画面
 */
export const ProfileRegistrant = ({ person, email, onUpload }: Props): JSX.Element => {
  const navigate = useNavigate()
  const setInProgress = useContext(InProgressContext)
  const [crop, setCrop] = useState<Crop>()
  const [image, setImage] = useState<Blob>()
  const [wait, setWait] = useState(true)

  useEffect(() => {
    //  一秒後に表示
    const timeout = setTimeout(() => {
      setWait(false)
      setInProgress?.(false)
    }, 1000)
    return () => clearTimeout(timeout)
  }, [])

  /**
   * personが更新されたとき、データがあれば、フロアマップ画面に遷移する
   */
  useEffect(() => {
    // console.log('person: ', person)
    if (person != null) {
      setInProgress?.(true)
      navigate('/')
    }
  }, [person])

  return (
    <>
      {
        wait
          ? <></>
          : <>
            <div className='profile-registrant-page'>
              <Grid margin={'30px'}>
                <Grid margin='0 10%' templateColumns='5fr 1fr' padding={'10px'} backgroundColor={'blue.10'}>
                  <Heading margin={'5px 10px'} level={4} textAlign='left'>プロフィール登録</Heading>
                </Grid>
                <Grid templateColumns={'1fr 4fr 4fr 1fr'}>
                  <Card
                    columnStart={'2'}
                    columnSpan={'1'}>
                    <ProfileImageField
                      label='プロフィール写真'
                      updatedBlob={setImage}
                      updatedCrop={setCrop} />
                  </Card>
                  <Card>
                    <PersonCreateForm
                      overrides={{
                        PersonUpdateForm: {
                          textAlign: 'left'
                        },
                        email: {
                          display: 'none'
                        },
                        crop: {
                          display: 'none'
                        },
                        tag_id: {
                          display: 'none'
                        },
                        admin: {
                          display: 'none'
                        },
                        assetCode: {
                          display: 'none'
                        },
                        SubmitButton: {
                          children: '登録'
                        },
                        CTAFlex: {
                          justifyContent: 'end'
                        }
                      }}
                      onValidate={{
                        name: PersonValidater(200),
                        roma: PersonValidater(200),
                        company: PersonValidater(200),
                        position: PersonValidater(200),
                        shortCompany: PersonValidater(8),
                        nickname: PersonValidater(8),
                        shrotPosition: PersonValidater(8),
                        job: PersonValidater(1000),
                        skills: PersonValidater(1000),
                        wishes: PersonValidater(1000),
                        favorites: PersonValidater(1000),
                        word: PersonValidater(1000, false)
                      }}
                      onSubmit={fields => {
                        // console.log('onSubmit: ', fields)
                        if (typeof image === 'undefined') {
                          throw new Error('onSubmit')
                        }
                        fields.email = email
                        fields.crop = JSON.stringify(crop)
                        return fields
                      }}
                      onSuccess={(fields) => {
                        // console.log('Profile Registrant Success: ', fields)
                        if (typeof image === 'undefined') {
                          throw new Error('onSubmit')
                        }
                        setInProgress?.(true)
                        image.arrayBuffer().then((data: ArrayBuffer) => {
                          // Upload image to Storage.
                          Storage.put(`${email}/profile.png`, data, { contentType: 'image/png' })
                            .then(onUpload)
                            .catch(console.error)
                        }).catch(console.error)
                      }}
                      onError={(fields, message) => {
                        // console.log('Profile Registrant Error: ', message, fields)
                      }}
                    />
                  </Card>
                </Grid>
              </Grid>

              <div className="profile-registrant-hooter">
                <View>
                  <Divider orientation="horizontal" />
                  <Text color={'#767C81'} fontSize={'12px'}>&copy; 2023 Macnica, Inc.</Text>
                </View>
              </div>
            </div>
          </>
      }
    </>
  )
}
