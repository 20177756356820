import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'react-image-crop/dist/ReactCrop.css'

import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Amplify, I18n } from 'aws-amplify'
import { AmplifyProvider, Authenticator, translations, Loader } from '@aws-amplify/ui-react'

import { App } from './App'
import aws_exports from './aws-exports'
import { studioTheme } from './my-ui-components'
import reportWebVitals from './reportWebVitals'

Amplify.configure(aws_exports)
I18n.putVocabularies(translations)
I18n.setLanguage('ja')

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <AmplifyProvider theme={studioTheme}>
    <Authenticator className='index-authenticator'>
      {({ signOut, user }) => (
        <BrowserRouter>
          {typeof user !== 'undefined' && typeof signOut !== 'undefined'
            ? <App signOut={signOut} user={user} />
            : <Loader />
          }
        </BrowserRouter>
      )}
    </Authenticator>
  </AmplifyProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
