import React from 'react'
import { Person, Whitelist } from '../models'
import { Crop } from 'react-image-crop'
import { API, DataStore, Storage } from 'aws-amplify'

const parser = new DOMParser()
const apiname = process.env.REACT_APP_HACCKE_API
if (typeof apiname === 'undefined') {
  throw new Error('apiname is undefined')
}

/**
 * Fetchable
 */
export interface Fetchable {
  fetch: (() => void) | (() => Promise<void>)
}

/*
 * Reflect value changes.
 */
export const onChange = (setter: React.Dispatch<string>) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setter(e.target.value)

/**
 * getPerson
 */
export const getPerson = async (assetCode?: string): Promise<Person | null> => await new Promise<Person | null>((resolve, reject) => {
  if (assetCode == null) {
    return null
  }
  DataStore.query(Person, p => p.assetCode.eq(assetCode)).then(result => {
    if (result.length === 0) {
      return null
    }
    resolve(result[0])
  }).catch(reject)
  return null
})

/**
 * getLogo
 */
export const getLogo = async (): Promise<Blob> => await new Promise<Blob>((resolve, reject) => {
  Storage.get('corporate/logo.png', { download: true, cacheControl: 'no-cache' }).then(result => {
    const blob = result.Body as Blob
    resolve(blob)
  }).catch(reject)
})

const floormapDict = new Map<string, Document>()

/**
 * フロアマップ取得
 */
export const fetchFloormap = async (floorId: string): Promise<Document> => await new Promise<Document>((resolve, reject) => {
  if (floormapDict.has(floorId)) {
    // console.debug('cache hit: ', floorId)
    resolve(floormapDict.get(floorId) as Document)
    return
  }
  API.get(apiname, `/floors/${floorId}/map`, {})
    .then(text => {
      if (text.status === 404) {
        throw new Error(`not found: ${floorId}`)
      }
      console.debug(`fetch /floors/${floorId}/map`)
      return text
    })
    .then(text => parser.parseFromString(text, 'application/xml'))
    .then(doc => {
      floormapDict.set(floorId, doc)
      resolve(doc)
    })
    .catch(reject)
})

/**
 * whitelistJudgment
 */
export const whitelistJudgment = (whitelist?: Whitelist[], person?: Person | null, email?: string | null): boolean => {
  // console.debug('whitelistJudgment ', whitelist, person, email)
  if ((person == null && email == null) || whitelist == null) {
    return false
  }
  if (person?.admin === true) {
    return true
  }
  if (person != null) {
    email = person.email
  }
  return whitelist.some(w => {
    if (email == null) {
      return false
    }
    switch (w.type) {
      case 'domain':
        return email.split('@')[1] === w.domain
      case 'email':
        return email === w.email
      default:
        return false
    }
  })
}

const profileDict = new Map<string, Promise<string>>()

/**
 * Fetch the user's profile image.
 */
export const fetchProfileImage = async (person: Person, blob?: Blob): Promise<string> => await new Promise<string>((resolve, reject) => {
  if (person.crop == null) {
    throw new Error()
  }
  let crop: Crop
  if (typeof person.crop === 'string') {
    crop = JSON.parse(person.crop) as Crop
  } else {
    crop = person.crop as Crop
  }
  if (typeof person.email !== 'string') {
    throw new Error()
  }
  const key = person.email + JSON.stringify(crop)
  if (profileDict.has(key)) {
    // console.debug('profile hit: ', key)
    resolve(profileDict.get(key) as Promise<string>)
    return
  }
  if (typeof blob !== 'undefined') {
    const value = cropProfile(blob)(crop)
    profileDict.set(key, value)
    resolve(value)
  } else {
    downloadProfileImage(person.email).then((blob: Blob) => {
      const value = cropProfile(blob)(crop)
      profileDict.set(key, value)
      resolve(value)
    }).catch(reject)
  }
})

/**
 * Download the user's profile image.
 */
export const downloadProfileImage = async (email: string, noCache: boolean = true): Promise<Blob> => await new Promise<Blob>((resolve, reject) => {
  if (noCache) {
    Storage.get(`${email}/profile.png`, { download: true, cacheControl: 'no-cache' }).then(result => {
      resolve(result.Body as Blob)
    }).catch(() => {
      Storage.get(`${email.replace('@', '-')}/profile.png`, { download: true, cacheControl: 'no-cache' }).then(result => {
        resolve(result.Body as Blob)
      }).catch(reject)
    })
  } else {
    Storage.get(`${email}/profile.png`, { download: true }).then(result => {
      resolve(result.Body as Blob)
    }).catch(() => {
      Storage.get(`${email.replace('@', '-')}/profile.png`, { download: true }).then(result => {
        resolve(result.Body as Blob)
      }).catch(reject)
    })
  }
})

/**
 * Generate a profile by cropping the imege.
 */
export const cropProfile = (origin: Blob) => async (crop: Crop) => await new Promise<string>((resolve) => {
  const canvas = document.createElement('canvas')
  canvas.width = crop.width
  canvas.height = crop.height

  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
  ctx.beginPath()
  ctx.arc(
    canvas.width / 2,
    canvas.height / 2,
    canvas.width / 2,
    0,
    2 * Math.PI,
    false
  )
  ctx.clip()

  const img = new Image()
  img.src = URL.createObjectURL(origin)
  img.onload = (e) => {
    ctx.drawImage(
      img,
      crop.x,
      crop.y,
      crop.width,
      crop.height,
      0,
      0,
      crop.width,
      crop.height
    )
    resolve(canvas.toDataURL())
  }
})

interface ValidationResponse {
  hasError: boolean
  errorMessage?: string
}

type ValidationFunction<T> = (value: T, validationResponse: ValidationResponse) => ValidationResponse | Promise<ValidationResponse>

/**
 * パーソン情報の検証
 * @param maxLength 最大長
 * @param required 必須
 * @returns 結果
 */
export const PersonValidater = (maxLength: number = 1000, required: boolean = true): ValidationFunction<string> =>
  (value) => required && value == null
    ? { hasError: required, errorMessage: 'The value is required' }
    : value == null
      ? { hasError: false }
      : value.length <= maxLength
        ? { hasError: false }
        : { hasError: true, errorMessage: `${maxLength} 文字以下で入力してください` }

/**
 * 表示用のエスケープ処理
 */
export const htmlspecialchars = (unsafeText?: string | null): string => {
  if (typeof unsafeText !== 'string') {
    return ''
  }
  return unsafeText.replace(
    /[&'`"<>]/g,
    (match: string): string => {
      return {
        '&': '&amp;',
        "'": '&#x27;',
        '`': '&#x60;',
        '"': '&quot;',
        '<': '&lt;',
        '>': '&gt;'
      }[match] ?? '?'
    }
  )
}
