// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { AllowFloor, Config, Whitelist, Person } = initSchema(schema);

export {
  AllowFloor,
  Config,
  Whitelist,
  Person
};