import React, { useEffect, useState } from 'react'
import { DataStore, Storage } from 'aws-amplify'
import { Config, Person, Whitelist } from '../models'
import { UserTableItem } from '../types'
import { ImageField, ModalWindow } from '.'
import { Button, ScrollView, TabItem, Table, TableBody, TableCell, TableHead, TableRow, Tabs, View, Text } from '@aws-amplify/ui-react'
import { ConfigUpdateForm, PersonUpdateForm, WhitelistCreateForm } from '../my-ui-components'
import { getLogo, whitelistJudgment } from '../lib'

interface Props {
  show: boolean
  persons: Person[]
  onClose: () => void
}

export const AdminModal = ({ show, persons, onClose }: Props): JSX.Element => {
  const [whitelist, setWhitelist] = useState<Whitelist[]>()
  const [configs, setConfigs] = useState<Config[]>()
  const [tableItems, setTableItems] = useState<UserTableItem[]>()
  const [selectedPerson, setSelectedPerson] = useState<Person>()
  const [selectedConfig, setSelectedConfig] = useState<Config>()
  const [corporateLogo, setCorporateLogo] = useState<Blob>()
  const [showPersonEditor, setShowPersonEditor] = useState(false)
  const [showConfigEditor, setShowConfigEditor] = useState(false)
  const [showAdditionalWhitelist, setShowAdditionalWhitelist] = useState(false)

  /**
   * 企業ロゴ取得
   */
  const fetchLogo = (): void => {
    getLogo().then(setCorporateLogo).catch(console.error)
  }

  /**
   * 設定の編集用(管理者以外では使用しないが、フェッチは行う)
   */
  const localfetchConfig = (): void => {
    DataStore.query(Config)
      .then(setConfigs)
      .catch(console.error)
  }

  const localfetchWhitelist = (): void => {
    DataStore.query(Whitelist)
      .then(setWhitelist)
      .catch(console.error)
  }

  /**
   * ホワイトリスト無効化
   */
  const whitelistUnapprovals = (email?: string | null): void => {
    if (whitelist == null || email == null) {
      return
    }
    const w = whitelist.find(w => w.email === email)
    if (typeof w !== 'undefined') {
      DataStore.delete(w).catch(console.error)
    }
    setWhitelist(whitelist.filter(w => w.email !== email))
  }

  useEffect(() => {
    localfetchConfig()
    localfetchWhitelist()
    fetchLogo()
  }, [])

  useEffect(() => {
    if (whitelist == null) {
      return
    }
    setTableItems(persons.map((person) => {
      return {
        person,
        approved: whitelistJudgment(whitelist, person)
      }
    }))
  }, [whitelist, persons])

  return (
    <>
      <ModalWindow
        show={show}
        title='管理機能'
        onClose={onClose}
        hiddenFooter={true}>
        <Tabs width={'700px'}>
          <TabItem title='ユーザー管理'>
            <ScrollView height="516px" >
              <Table highlightOnHover={true}>
                <TableHead>
                  <TableRow>
                    <TableCell as="th">メールアドレス</TableCell>
                    <TableCell as="th">状態</TableCell>
                    {/* <TableCell as="th">承認</TableCell> */}
                    <TableCell as="th">編集</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    tableItems?.map(({ person, approved }, key) => {
                      if (typeof (approved) !== 'boolean') {
                        throw new Error()
                      }
                      return (
                        <TableRow key={key}>
                          <TableCell>{person.email}</TableCell>
                          <TableCell>{approved ? '承認済み' : '未承認'}</TableCell>
                          <TableCell>{
                            <Button onClick={() => {
                              setSelectedPerson(person)
                              setShowPersonEditor(true)
                            }}>編集</Button>
                          }</TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </ScrollView>
            <ModalWindow
              title={`ユーザー編集 - ${selectedPerson?.name ?? ''}`}
              onClose={() => setShowPersonEditor(false)}
              hiddenFooter={true}
              show={showPersonEditor}>
              <ScrollView height={'500px'}>
                <PersonUpdateForm
                  onSuccess={() => {
                    setShowPersonEditor(false)
                  }}
                  id={selectedPerson?.id}
                  overrides={{
                    admin: {
                      display: 'none'
                    }
                  }}
                  person={selectedPerson} />
              </ScrollView>
            </ModalWindow>
          </TabItem>
          <TabItem title='ホワイトリスト管理'>
            <ScrollView height="490px" >
              <Table highlightOnHover={true}>
                <TableHead>
                  <TableRow>
                    <TableCell as="th">承認済み</TableCell>
                    <TableCell as="th">削除</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    whitelist?.filter(w => w.type === 'email').map((white, key) => {
                      return (
                        <TableRow key={key}>
                          <TableCell>{white.email}</TableCell>
                          <TableCell>{
                            <Button
                              variation='warning'
                              onClick={() => {
                                whitelistUnapprovals(white.email)
                              }}>削除</Button>
                          }</TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </ScrollView>
            <View height='50px'>
              <Button variation='primary' onClick={() => setShowAdditionalWhitelist(true)}>追加する</Button>
            </View>
            <ModalWindow
              title={'ホワイトリスト追加'}
              onClose={() => setShowAdditionalWhitelist(false)}
              hiddenFooter={true}
              show={showAdditionalWhitelist}>
              <ScrollView height={'180px'}>
                <WhitelistCreateForm
                  onSuccess={() => {
                    localfetchWhitelist()
                    setShowAdditionalWhitelist(false)
                  }}
                  onSubmit={(field) => {
                    field.type = 'email'
                    return field
                  }}
                  overrides={{
                    domain: {
                      display: 'none'
                    },
                    type: {
                      display: 'none'
                    }
                  }} />
              </ScrollView>
            </ModalWindow>
          </TabItem>
          <TabItem title='各種設定'>
            <ScrollView height="540px" >
              <Table highlightOnHover={true}>
                <TableHead>
                  <TableRow>
                    <TableCell as="th">項目</TableCell>
                    <TableCell as="th">説明</TableCell>
                    <TableCell as="th">設定</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    configs?.map((config, key) => {
                      return (
                        <TableRow key={key}>
                          <TableCell>{config.name}</TableCell>
                          <TableCell>
                            <Text width={'360px'}>{config.description}</Text>
                          </TableCell>
                          <TableCell>{
                            <Button onClick={() => {
                              setSelectedConfig(config)
                              setShowConfigEditor(true)
                            }}>編集</Button>
                          }</TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </ScrollView>
            <ModalWindow
              title={`設定編集 - ${selectedConfig?.name ?? ''}`}
              onClose={() => setShowConfigEditor(false)}
              width={'600px'}
              hiddenFooter={true}
              show={showConfigEditor}>
              <ConfigUpdateForm
                onSuccess={() => {
                  localfetchConfig()
                  setShowConfigEditor(false)
                }}
                id={selectedConfig?.id}
                overrides={{
                  name: {
                    isReadOnly: true
                  }
                }}
                config={selectedConfig} />
            </ModalWindow>
          </TabItem>
          <TabItem title='企業ロゴ設定'>
            <Text color='red'>ロゴはpng画像を使用してください。</Text>
            <Text>ロゴ選択後はリロードが必要です。</Text>
            <ScrollView height="516px">
              <ImageField
                init={corporateLogo}
                accept='image/png'
                onUpload={(logo) => {
                  // console.log('on upload')
                  logo?.arrayBuffer().then(data => {
                    Storage.put('corporate/logo.png', data, { contentType: 'image/png' }).then(() => {
                      fetchLogo()
                    }).catch(console.error)
                  }).catch(console.error)
                }} />
            </ScrollView>
          </TabItem>
        </Tabs>
      </ModalWindow>
    </>
  )
}
