import './ImageField.css'

import { nanoid } from 'nanoid'
import React, { CSSProperties, useEffect, useState } from 'react'

interface Props {
  label?: string
  style?: CSSProperties | undefined
  init?: Blob
  accept?: string
  onUpload?: (blob?: Blob) => void
}

/**
 * A field to upload a profile image.
 */
export const ImageField = ({ label, style, init, accept = 'image/*', onUpload }: Props): JSX.Element => {
  const [fileInputId] = useState(nanoid())
  const [blob, setBlob] = useState<Blob>()
  const [draggingOver, setDraggingOver] = useState(false)
  const [imageUrl, setImageUrl] = useState<string>()

  useEffect(() => {
    if (init != null) {
      setImageUrl(URL.createObjectURL(init))
    }
  }, [init])

  /**
   * Update image url.
   */
  useEffect(() => {
    if (blob != null) {
      setImageUrl(URL.createObjectURL(blob))
      onUpload?.(blob)
    }
  }, [blob])

  /**
   * Event handler for dragging.
   */
  const onDrag = (e: React.DragEvent<HTMLDivElement>): void => {
    const newDraggingOver = e.type === 'dragover'
    if (newDraggingOver !== draggingOver) {
      setDraggingOver(newDraggingOver)
    }
    e.preventDefault()
  }

  /**
   * Event handler for drop.
   */
  const onDrop = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault()
    const inputElement = document.getElementById(fileInputId) as HTMLInputElement
    inputElement.files = e.dataTransfer.files
    setDraggingOver(false)
    setBlob(e.dataTransfer.files[0])
  }

  /**
   * Event handler for image selection.
   */
  const imageSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setBlob(e.target.files?.[0])
  }

  return (
    <div style={{
      width: '660px',
      height: '502px'
    }}>
      <label htmlFor={fileInputId}>{label}</label>
      <div
        className={`center-block image-field ${draggingOver ? 'image-field-dragging-over ' : ''}`}
        style={style}
        onDrop={onDrop}
        onDragOverCapture={onDrag}
        onDragLeaveCapture={onDrag}>
        <div className="image-field-drag-drop-area d-flex align-items-center justify-content-center">
          {typeof imageUrl === 'undefined'
            ? <label htmlFor={fileInputId} className={'form-label image-field-drag-drop-area-label'}>
              ここにファイルをドロップ<br />
              または
            </label>
            : <img className='image-field-image' src={imageUrl} alt='preview' />
          }
        </div>
        <input className='form-control' type="file" accept={accept} name='photo' id={fileInputId} onChange={imageSelected} />
      </div>
    </div>
  )
}
