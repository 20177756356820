/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_appsync_graphqlEndpoint": "https://zkqciw477bgflnnciqg2vyx3ri.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-uj46df2u7ndbxmkws2wrn5arty",
    "aws_cloud_logic_custom": [
        {
            "name": "hacckeWrapper",
            "endpoint": "https://t9n9gd6l42.execute-api.ap-northeast-1.amazonaws.com/arch",
            "region": "ap-northeast-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-northeast-1:ba8b1f54-9fb5-4c7b-b848-96a1f013273b",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_hew3dTbUq",
    "aws_user_pools_web_client_id": "dj2v2c97p2fmm8sbc7dsn1lkj",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "provisional-pld-storage-214d7bd964546-arch",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
