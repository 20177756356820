import './ProfileEditor.css'

import React, { useContext, useEffect, useState } from 'react'

import { Storage } from 'aws-amplify'
import { Text, Divider, View, Grid, Card, Heading, Loader } from '@aws-amplify/ui-react'
import { Crop } from 'react-image-crop'
import { useNavigate } from 'react-router-dom'

import { ProfileImageField } from '../../components'
import { PersonUpdateForm } from '../../my-ui-components'
import { downloadProfileImage, PersonValidater } from '../../lib'
import { InProgressContext } from '../../contexts'
import { Person } from '../../models'

interface Props {
  person?: Person | null
}

/**
 * プロフィール編集画面
 */
export const ProfileEditor = ({ person }: Props): JSX.Element => {
  const navigate = useNavigate()
  const setInProgress = useContext(InProgressContext)

  const [crop, setCrop] = useState<Crop>()
  const [image, setImage] = useState<Blob>()
  const [profileImage, setProfileImage] = useState(<Loader />)
  const [wait, setWait] = useState(true)

  const close = (): void => {
    navigate('/floor-map')
  }

  useEffect(() => {
    if (person == null) {
      return
    }

    downloadProfileImage(person.email)
      .then(setImage)
      .catch(console.error)

    if (typeof person.crop === 'string') {
      const crop = JSON.parse(person.crop) as Crop
      setCrop(crop)
    } else if (person.crop != null) {
      const crop = person.crop as Crop
      setCrop(crop)
    }
  }, [person])

  /** プロフィール写真表示 */
  useEffect(() => {
    setProfileImage(
      <ProfileImageField
        label='プロフィール写真'
        initBlob={image}
        initCrop={crop}
        waitInit={true}
        updatedBlob={setImage}
        updatedCrop={setCrop} />
    )
    setWait(false)
    setInProgress?.(false)
  }, [image, crop])

  if (person == null) {
    //  登録画面へ
    navigate('/profile-registrant')
    return <></>
  }

  if (wait) {
    return <></>
  }

  return (
    <div className='profile-registrant-page'>
      <Grid>
        <Grid margin='30px 10%' templateColumns='5fr 1fr' padding={'10px'} backgroundColor={'blue.10'}>
          <Heading margin={'5px 10px'} level={4} textAlign='left'>プロフィール編集</Heading>
          <Grid margin={'auto'}>
            <button type="button" className="btn-close modal-window-close" onClick={close} aria-label="Close"></button>
          </Grid>
        </Grid>
        <Grid templateColumns={'1fr 4fr 4fr 1fr'}>
          <Card
            columnStart={'2'}
            columnSpan={'1'}>
            {profileImage}
          </Card>
          <Card>
            <PersonUpdateForm
              id={person.id}
              person={person}
              overrides={{
                PersonUpdateForm: {
                  textAlign: 'left'
                },
                email: {
                  display: 'none'
                },
                crop: {
                  display: 'none'
                },
                tag_id: {
                  display: 'none'
                },
                admin: {
                  display: 'none'
                },
                assetCode: {
                  display: 'none'
                },
                CTAFlex: {
                  justifyContent: 'end'
                }
              }}
              onCancel={close}
              onValidate={{
                name: PersonValidater(200),
                roma: PersonValidater(200),
                company: PersonValidater(200),
                position: PersonValidater(200),
                shortCompany: PersonValidater(8),
                nickname: PersonValidater(8),
                shrotPosition: PersonValidater(8),
                job: PersonValidater(1000),
                skills: PersonValidater(1000),
                wishes: PersonValidater(1000),
                favorites: PersonValidater(1000),
                word: PersonValidater(1000, false)
              }}
              onSubmit={(fields) => {
                if (typeof image === 'undefined') {
                  throw new Error('onSubmit')
                }
                setInProgress?.(true)
                console.debug('onSubmit: ', fields)
                fields.crop = JSON.stringify(crop)
                return fields
              }}
              onSuccess={(fields) => {
                if (typeof image === 'undefined') {
                  throw new Error('onSubmit')
                }
                console.debug('onSuccess: ', fields)

                image.arrayBuffer().then((data: ArrayBuffer) => {
                  // Upload image to Storage.
                  Storage.put(`${person.email}/profile.png`, data, { contentType: 'image/png' }).then(async () => {
                    navigate('/floor-map')
                  }).catch(console.error)
                }).catch(console.error)
              }}
            />
          </Card>
        </Grid>
      </Grid>

      <div className="profile-registrant-hooter">
        <View>
          <Divider orientation="horizontal" />
          <Text color={'#767C81'} fontSize={'12px'}>&copy; 2023 Macnica, Inc.</Text>
        </View>
      </div>
    </div>
  )
}
