import './Profile.css'
import React, { useEffect, useState } from 'react'
import { ProfileDetailsDesign, ProfileSummaryDesign } from '../../my-ui-components'
import { fetchProfileImage } from '../../lib'
import { Person } from '../../models'

interface Props {
  // user: AmplifyUser
  person?: Person
  origin?: Blob
}

/**
 * Profile
 */
export const Profile = ({ person, origin }: Props): JSX.Element => {
  const [image, setImage] = useState<string>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [detailTitle, setDetailTitle] = useState<string>()
  const [detailContent, setDetailContent] = useState<string | null>()

  if (typeof person === 'undefined') {
    return (<></>)
  }

  useEffect(() => {
    if (typeof person.crop === 'undefined') {
      return
    }
    fetchProfileImage(person, origin).then(img => {
      setImage(img)
    }).catch(console.error)
  }, [person, origin])

  return (
    <>
      {
        detailTitle != null
          ? <ProfileDetailsDesign
            person={person}
            overrides={{
              image: {
                src: image
              },
              profileBackground: {
                className: 'profile-background'
              },
              HeaderLower: {
                className: 'profile-header-lower'
              },
              line: {
                className: 'path-line'
              },
              title: {
                className: 'profile-title',
                children: detailTitle
              },
              container: {
                className: 'profile-details-container'
              },
              content: {
                className: 'profile-details-content',
                children: detailContent
              },
              back: {
                as: 'button',
                onClick: () => setDetailTitle(undefined)
              }
            }}
          />
          : <ProfileSummaryDesign
            person={person}
            overrides={{
              image: {
                src: image
              },
              profileBackground: {
                className: 'profile-background'
              },
              HeaderLower: {
                className: 'profile-header-lower'
              },
              line: {
                className: 'path-line'
              },
              jobContent: {
                className: 'profile-content',
                display: '-webkit-box'
              },
              jobMore: {
                as: 'button',
                onClick: () => {
                  setDetailTitle('仕事内容 / 関心のあるテーマ')
                  setDetailContent(person.job)
                }
              },
              skillsContent: {
                className: 'profile-content',
                display: '-webkit-box'
              },
              skillsMore: {
                as: 'button',
                onClick: () => {
                  setDetailTitle('スキルや資格')
                  setDetailContent(person.skills)
                }
              },
              wishesContent: {
                className: 'profile-content',
                display: '-webkit-box'
              },
              wishesMore: {
                as: 'button',
                onClick: () => {
                  setDetailTitle('欲しい情報')
                  setDetailContent(person.wishes)
                }
              },
              favoritesContent: {
                className: 'profile-content',
                display: '-webkit-box'
              },
              favoritesMore: {
                as: 'button',
                onClick: () => {
                  setDetailTitle('趣味・好きなこと')
                  setDetailContent(person.favorites)
                }
              },
              wordContent: {
                className: 'profile-content-oneline',
                whiteSpace: 'nowrap',
                width: '370px'
              },
              wordMore: {
                as: 'button',
                onClick: () => {
                  setDetailTitle('一言(任意)')
                  setDetailContent(person.word)
                }
              }
            }} />
      }
    </>
  )
}
