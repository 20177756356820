/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Icon, Image, Text, View } from "@aws-amplify/ui-react";
export default function ProfileSummaryDesign(props) {
  const { person, overrides, ...rest } = props;
  return (
    <View
      width="402px"
      height="802px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      border="1px SOLID rgba(179,179,179,1)"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "ProfileSummaryDesign")}
    >
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="625px"
        justifyContent="flex-start"
        alignItems="flex-start"
        position="absolute"
        top="175px"
        left="0px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "ProfileDesignBodySummary")}
      >
        <Flex
          gap="5px"
          direction="column"
          width="400px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="15px 15px 15px 15px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "job")}
        >
          <Text
            fontFamily="BIZ UDPGothic"
            fontSize="14px"
            fontWeight="700"
            color="rgba(0,0,205,1)"
            lineHeight="14px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="仕事内容 / 関心のあるテーマ"
            {...getOverrideProps(overrides, "title13382")}
          ></Text>
          <Text
            fontFamily="BIZ UDPGothic"
            fontSize="14px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="14px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="42px"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={person?.job}
            {...getOverrideProps(overrides, "jobContent")}
          ></Text>
          <Text
            fontFamily="BIZ UDPGothic"
            fontSize="14px"
            fontWeight="400"
            color="rgba(0,0,205,1)"
            lineHeight="14px"
            textAlign="right"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="12px"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="続きを見る"
            {...getOverrideProps(overrides, "jobMore")}
          ></Text>
        </Flex>
        <Flex
          gap="5px"
          direction="column"
          width="400px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="15px 15px 15px 15px"
          backgroundColor="rgba(240,240,240,1)"
          {...getOverrideProps(overrides, "skills")}
        >
          <Text
            fontFamily="BIZ UDPGothic"
            fontSize="14px"
            fontWeight="700"
            color="rgba(0,0,205,1)"
            lineHeight="14px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="スキルや資格"
            {...getOverrideProps(overrides, "title13399")}
          ></Text>
          <Text
            fontFamily="BIZ UDPGothic"
            fontSize="14px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="14px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="42px"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={person?.skills}
            {...getOverrideProps(overrides, "skillsContent")}
          ></Text>
          <Text
            fontFamily="BIZ UDPGothic"
            fontSize="14px"
            fontWeight="400"
            color="rgba(0,0,205,1)"
            lineHeight="14px"
            textAlign="right"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="12px"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="続きを見る"
            {...getOverrideProps(overrides, "skillsMore")}
          ></Text>
        </Flex>
        <Flex
          gap="5px"
          direction="column"
          width="400px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="15px 15px 15px 15px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "wishes")}
        >
          <Text
            fontFamily="BIZ UDPGothic"
            fontSize="14px"
            fontWeight="700"
            color="rgba(0,0,205,1)"
            lineHeight="14px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="欲しい情報"
            {...getOverrideProps(overrides, "title13394")}
          ></Text>
          <Text
            fontFamily="BIZ UDPGothic"
            fontSize="14px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="14px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="42px"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={person?.wishes}
            {...getOverrideProps(overrides, "wishesContent")}
          ></Text>
          <Text
            fontFamily="BIZ UDPGothic"
            fontSize="14px"
            fontWeight="400"
            color="rgba(0,0,205,1)"
            lineHeight="14px"
            textAlign="right"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="12px"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="続きを見る"
            {...getOverrideProps(overrides, "wishesMore")}
          ></Text>
        </Flex>
        <Flex
          gap="5px"
          direction="column"
          width="400px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="15px 15px 15px 15px"
          backgroundColor="rgba(240,240,240,1)"
          {...getOverrideProps(overrides, "favorites")}
        >
          <Text
            fontFamily="BIZ UDPGothic"
            fontSize="14px"
            fontWeight="700"
            color="rgba(0,0,205,1)"
            lineHeight="14px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="趣味・好きなこと"
            {...getOverrideProps(overrides, "title133104")}
          ></Text>
          <Text
            fontFamily="BIZ UDPGothic"
            fontSize="14px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="14px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="42px"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={person?.favorites}
            {...getOverrideProps(overrides, "favoritesContent")}
          ></Text>
          <Text
            fontFamily="BIZ UDPGothic"
            fontSize="14px"
            fontWeight="400"
            color="rgba(0,0,205,1)"
            lineHeight="14px"
            textAlign="right"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="12px"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="続きを見る"
            {...getOverrideProps(overrides, "favoritesMore")}
          ></Text>
        </Flex>
        <Flex
          gap="5px"
          direction="column"
          width="400px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="15px 15px 15px 15px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "word")}
        >
          <Text
            fontFamily="BIZ UDPGothic"
            fontSize="14px"
            fontWeight="700"
            color="rgba(0,0,205,1)"
            lineHeight="14px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="一言(任意)"
            {...getOverrideProps(overrides, "title133109")}
          ></Text>
          <Text
            fontFamily="BIZ UDPGothic"
            fontSize="14px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="14px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="42px"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={person?.word}
            {...getOverrideProps(overrides, "wordContent")}
          ></Text>
          <Text
            fontFamily="BIZ UDPGothic"
            fontSize="14px"
            fontWeight="400"
            color="rgba(0,0,205,1)"
            lineHeight="14px"
            textAlign="right"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="12px"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="続きを見る"
            {...getOverrideProps(overrides, "wordMore")}
          ></Text>
        </Flex>
      </Flex>
      <View
        width="400px"
        height="175px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0px"
        left="0px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "ProfileDesignHeader")}
      >
        <Flex
          gap="0"
          direction="column"
          width="400px"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          position="absolute"
          top="0px"
          left="0px"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "profileBackground")}
        >
          <Flex
            gap="5px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="22px 25px 8px 25px"
            {...getOverrideProps(overrides, "HeaderUpper")}
          >
            <Text
              fontFamily="BIZ UDPGothic"
              fontSize="24px"
              fontWeight="400"
              color="rgba(255,255,255,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={person?.name}
              {...getOverrideProps(overrides, "name")}
            ></Text>
            <Text
              fontFamily="BIZ UDPGothic"
              fontSize="12px"
              fontWeight="400"
              color="rgba(255,255,255,1)"
              lineHeight="12px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={person?.roma}
              {...getOverrideProps(overrides, "roma")}
            ></Text>
            <Icon
              width="230px"
              height="0px"
              viewBox={{ minX: 0, minY: 0, width: 230, height: 1 }}
              paths={[
                {
                  d: "M0 0L230 0L230 -1L0 -1L0 0Z",
                  stroke: "rgba(168,167,221,1)",
                  fillRule: "nonzero",
                  strokeWidth: 1,
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              {...getOverrideProps(overrides, "line")}
            ></Icon>
            <Text
              fontFamily="BIZ UDPGothic"
              fontSize="12px"
              fontWeight="400"
              color="rgba(255,255,255,1)"
              lineHeight="12px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={person?.email}
              {...getOverrideProps(overrides, "email")}
            ></Text>
          </Flex>
          <Flex
            gap="6px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="10px 25px 10px 25px"
            {...getOverrideProps(overrides, "HeaderLower")}
          >
            <Flex
              gap="0"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 9")}
            >
              <Text
                fontFamily="BIZ UDPGothic"
                fontSize="10px"
                fontWeight="700"
                color="rgba(85,85,85,1)"
                lineHeight="10px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="会社名"
                {...getOverrideProps(overrides, "companyLabel")}
              ></Text>
              <Text
                fontFamily="BIZ UDPGothic"
                fontSize="12px"
                fontWeight="400"
                color="rgba(51,51,51,1)"
                lineHeight="12px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={person?.company}
                {...getOverrideProps(overrides, "company")}
              ></Text>
            </Flex>
            <Flex
              gap="0"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 10")}
            >
              <Text
                fontFamily="BIZ UDPGothic"
                fontSize="10px"
                fontWeight="700"
                color="rgba(85,85,85,1)"
                lineHeight="10px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="部署名 / 役職"
                {...getOverrideProps(overrides, "positionLabel")}
              ></Text>
              <Text
                fontFamily="BIZ UDPGothic"
                fontSize="12px"
                fontWeight="400"
                color="rgba(51,51,51,1)"
                lineHeight="12px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={person?.position}
                {...getOverrideProps(overrides, "posotion")}
              ></Text>
            </Flex>
          </Flex>
        </Flex>
        <Image
          width="122px"
          height="122px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="24px"
          left="249px"
          border="1px SOLID rgba(255,255,255,1)"
          borderRadius="71px"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          {...getOverrideProps(overrides, "image")}
        ></Image>
      </View>
    </View>
  );
}
