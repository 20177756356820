import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Text, Divider, SelectField, Button } from '@aws-amplify/ui-react'
import { ChangePasswordModal } from './ChangePasswordModal'
import { FloorMapDataModel } from '../types'
import { Person } from '../models'
import { ConfigContext } from '../contexts'

type _setShowModal = (value: boolean) => void

interface Props {
  person: Person
  selectedFloor?: FloorMapDataModel
  floormaps?: FloorMapDataModel[]
  setSelectedFloor: (selected?: FloorMapDataModel) => void
  setShowAdminModal: _setShowModal
  setShowBeerActionModal: _setShowModal
  setShowLogoutModal: _setShowModal
}

export const FloorMapMenu = ({ person, selectedFloor, floormaps, setSelectedFloor, setShowAdminModal, setShowBeerActionModal, setShowLogoutModal }: Props): JSX.Element => {
  const navigate = useNavigate()
  const configContext = useContext(ConfigContext)
  const [showPasswordChanger, setShowPasswordChanger] = useState(false)

  /**
   * プロフィール編集
   */
  const editProfile = (e: React.MouseEvent<HTMLButtonElement>): void => navigate('/profile-editor')

  if (configContext == null) {
    return (<></>)
  }

  return (
    <>
      <div className={'floor-map-menu'}>
        <Text margin={'20px 10px auto'} fontSize={'xl'}>{person.company}</Text>
        <Text margin={'10px auto 20px'} fontSize={'xl'}>{person.name}</Text>
        <SelectField
          margin={'10px auto'}
          fontSize='large'
          size='large'
          label="フロア選択"
          className='w-75'
          onChange={(e) => {
            console.debug('selected floor: value: ', e.target.value)
            const selected = floormaps?.find(f => f.id.toString() === e.target.value.toString())
            setSelectedFloor(selected)
          }}
          value={selectedFloor?.id.toString()} >
          {floormaps?.map((item, key) => (<option value={item.id} key={key}>{item.name}</option>))}
        </SelectField>
        <Button fontSize='large' margin={'10px auto'} className='btn btn-light w-75' onClick={editProfile}>プロフィール編集</Button>
        <Button fontSize='large' margin={'10px auto'} className='btn btn-light w-75' onClick={() => setShowPasswordChanger(true)}>パスワード変更</Button>
        {configContext.beer ? <Button fontSize='large' margin={'10px auto'} className='btn btn-light w-75' onClick={() => setShowBeerActionModal(true)}>CAFEで一杯！</Button> : <></>}
        {
          person.admin === true
            ? <Button
              fontSize={'large'}
              onClick={() => {
                setShowAdminModal(true)
              }}
              className='btn btn-light w-75'
              margin={'10px auto'}>管理機能</Button>
            : <></>
        }
        <Divider margin={'40px auto'} orientation="horizontal" />
        <Button fontSize={'large'} margin={'10px auto'} className='btn btn-light w-75' onClick={() => setShowLogoutModal?.(true)}>ログアウト</Button>
        <Text fontSize={'large'} className='floor-map-menu-copyright' color={'#767C81'}>&copy; 2023 Macnica, Inc.</Text>
        <ChangePasswordModal show={showPasswordChanger} onClose={() => setShowPasswordChanger(false)} />
      </div>
    </>
  )
}
