import './InProgress.css'

import React from 'react'
import { Loader } from '@aws-amplify/ui-react'

interface Props {
  inProgess?: boolean
}

export const InProgress = ({ inProgess }: Props): JSX.Element => {
  if (inProgess === true) {
    return (
      <div className="in-progress-overlay">
        <Loader size='large'/>
      </div>
    )
  } else {
    return <></>
  }
}
