import { fetchProfileImage, htmlspecialchars } from '../lib'
import { Person } from '../models'
import { UserIconDataModel } from '../types'

const orange = '#FF6600'
const navy = '#15304D'

interface UserIconProps {
  // eslint-disable-next-line no-trailing-spaces
  /** ユーザーアイコンのベースとなるSVGドキュメント  
   * これをディープクローンして、表示用ユーザーアイコンを生成する
   */
  origin: Document
  /** 表示するパーソンのデータ */
  dataModel: UserIconDataModel
  beer: boolean
  userIconVariation: number
  /** プロフィール表示を行うコールバック */
  showProfile: (person: Person, right: boolean) => void
}

/**
 * 表示ユーザーアイコンを生成する
 */
export const makeUsericon = async ({ origin, dataModel, beer, userIconVariation, showProfile }: UserIconProps): Promise<Node> => {
  if (origin == null) {
    return origin
  }
  const id = dataModel.person.id
  const viewModel = origin.cloneNode(true) as Document
  const pattern = viewModel.getElementById('pattern0')
  const patternUse = viewModel.getElementById('pattern_use')
  const image = viewModel.getElementById('image0_0_1')
  const imageRect = viewModel.getElementById('image_rect')
  const center = viewModel.getElementById('center')
  const lower = viewModel.getElementById('lower')
  const upper = viewModel.getElementById('upper')
  const beericon = viewModel.getElementById('BeerIcon')
  const imageBorder = viewModel.getElementById('image_border')
  const contextBorder = viewModel.getElementById('context_border')
  const pointPath = viewModel.getElementById('point_path')
  const patternId = `pattern_${id}`
  const imageId = `image_${id}`

  if (imageRect == null) {
    throw new Error('imageRect is null')
  }
  if (upper == null) {
    throw new Error('upper is null')
  }
  if (lower == null) {
    throw new Error('lower is null')
  }
  if (center == null) {
    throw new Error('center is null')
  }
  if (pattern == null) {
    throw new Error('pattern is null')
  }
  if (patternUse == null) {
    throw new Error('patternUse is null')
  }
  if (image == null) {
    throw new Error('image is null')
  }
  if (beericon == null) {
    throw new Error('beericon is null')
  }
  if (imageBorder == null) {
    throw new Error('imageBorder is null')
  }
  if (contextBorder == null) {
    throw new Error('contextBorder is null')
  }
  if (pointPath == null) {
    throw new Error('pointPath is null')
  }

  imageRect.setAttribute('fill', `url(#${patternId})`)

  pattern.setAttribute('id', patternId)
  patternUse.setAttribute('xlink:href', `#${imageId}`)

  image.setAttribute('id', imageId)

  let color = ''
  if (beer) {
    color = orange
  } else {
    beericon.setAttribute('display', 'none')
    color = navy
  }
  imageBorder.setAttribute('stroke', color)

  contextBorder.setAttribute('stroke', color)

  pointPath.setAttribute('fill', color)

  upper.setAttribute('style', 'user-select: none;')
  lower.setAttribute('style', 'user-select: none;')
  center.setAttribute('style', 'user-select: none;')
  switch (userIconVariation) {
    case 1:
      upper.innerHTML = ''
      lower.innerHTML = ''
      center.innerHTML = htmlspecialchars(dataModel.person.nickname) ?? ''
      break
    case 2:
      upper.innerHTML = htmlspecialchars(dataModel.person.shortCompany) ?? ''
      lower.innerHTML = htmlspecialchars(dataModel.person.nickname) ?? ''
      center.innerHTML = ''
      break
    case 3:
    default:
      upper.innerHTML = htmlspecialchars(dataModel.person.shrotPosition) ?? ''
      lower.innerHTML = htmlspecialchars(dataModel.person.nickname) ?? ''
      center.innerHTML = ''
      break
  }

  try {
    const href = await fetchProfileImage(dataModel.person)
    image.setAttribute('href', href)
  } catch (error) {
    ;
  }

  const root = viewModel.getElementById('root')
  if (root == null) {
    throw new Error('not root')
  }

  const size = 2.2
  const x = dataModel.position.x
  const y = dataModel.position.y
  root.setAttribute('x', `${x - (size / 2)}`)
  root.setAttribute('y', `${y}`)
  root.setAttribute('height', size.toString())
  root.setAttribute('width', size.toString())

  const usericon = viewModel.getElementById('UserIconDesign')
  if (usericon == null) {
    throw new Error()
  }
  usericon.setAttribute('transform', 'scale(1, -1) translate(0 -138)')
  usericon.addEventListener('click', (e) => {
    const right = document.body.scrollWidth / 2 - e.x > 0
    showProfile(dataModel.person, right)
    e.stopPropagation()
  })

  return root
}
