import './ProfileImageField.css'

import { nanoid } from 'nanoid'
import React, { CSSProperties, useEffect, useState } from 'react'
import ReactCrop, { Crop } from 'react-image-crop'
import Resizer from 'react-image-file-resizer'

interface Props {
  label?: string
  style?: CSSProperties | undefined
  initBlob?: Blob
  initCrop?: Crop
  waitInit?: boolean
  updatedCrop?: (crop?: Crop) => void
  updatedBlob?: (blob?: Blob) => void
}

/**
 * A field to upload a profile image.
 */
export const ProfileImageField = ({ label, style, initBlob, initCrop, waitInit, updatedCrop, updatedBlob }: Props): JSX.Element => {
  const [fileInputId] = useState(nanoid())

  const [blob, setBlob] = useState(initBlob)
  const [crop, setCrop] = useState(initCrop)
  const [draggingOver, setDraggingOver] = useState(false)
  const [imageUrl, setImageUrl] = useState<string>()
  const [wait, setWait] = useState(waitInit)

  useEffect(() => {
    setBlob(initBlob)
  }, [initBlob])

  useEffect(() => {
    setCrop(initCrop)
  }, [initCrop])

  /**
   * Update image url.
   */
  useEffect(() => {
    if (typeof blob !== 'undefined') {
      setImageUrl(URL.createObjectURL(blob))
    } else {
      setImageUrl(undefined)
    }
    updatedBlob?.(blob)
  }, [blob])

  /**
   * Initialize Crop on load image.
   */
  const onLoadImage = (e: React.SyntheticEvent<HTMLImageElement>): void => {
    if (wait === true) {
      setWait(false)
      return
    }
    const target = e.target as HTMLImageElement
    const min = (target.height > target.width ? target.width : target.height) - 30
    const x = (target.width - min) / 2
    const y = (target.height - min) / 2
    const newCrop: Crop = {
      unit: 'px',
      x,
      y,
      width: min,
      height: min
    }
    setCrop(newCrop)
    updatedCrop?.(newCrop)
  }

  /**
   * Event handler for dragging.
   */
  const onDrag = (e: React.DragEvent<HTMLDivElement>): void => {
    const newDraggingOver = e.type === 'dragover'
    if (newDraggingOver !== draggingOver) {
      setDraggingOver(newDraggingOver)
    }
    e.preventDefault()
  }

  /**
   * Event handler for drop.
   */
  const onDrop = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault()
    const inputElement = document.getElementById(fileInputId) as HTMLInputElement
    inputElement.files = e.dataTransfer.files
    setDraggingOver(false)
    resize(e.dataTransfer.files[0]).then(result => {
      setBlob(result)
    }).catch(console.error)
  }

  /**
   * Event handler for image selection.
   */
  const imageSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
    resize(e.target.files?.[0]).then(result => {
      setBlob(result)
    }).catch(console.error)
  }

  return (
    <div style={{
      width: '660px',
      height: '502px'
    }}>
      <label htmlFor={fileInputId}>{label}</label>
      <div
        className={`center-block profile-image-field ${draggingOver ? 'profile-image-field-dragging-over ' : ''}`}
        style={style}
        onDrop={onDrop}
        onDragOverCapture={onDrag}
        onDragLeaveCapture={onDrag}>
        <div className="drag-drop-area d-flex align-items-center justify-content-center">
          {typeof imageUrl === 'undefined'
            ? <label htmlFor={fileInputId} className={'form-label drag-drop-area-label'}>
              ここにファイルをドロップ<br />
              または
            </label>
            : <ReactCrop
              crop={crop ?? undefined}
              onComplete={(crop) => {
                setCrop(crop)
                updatedCrop?.(crop)
              }}
              onChange={(crop) => { setCrop(crop) }}
              aspect={1}
              circularCrop={true}
              minHeight={100}
              minWidth={100}
              keepSelection={true}>
              <img className='profile-image-field-image' src={imageUrl} alt='preview' onLoad={onLoadImage} />
            </ReactCrop>
          }
        </div>
        <input className='form-control' type="file" accept='image/*' name='photo' id={fileInputId} onChange={imageSelected} />
      </div>
    </div>
  )
}

/**
 * Resize
 * @param blob
 * @returns
 */
export const resize = async (blob: Blob | undefined): Promise<Blob> => await new Promise((resolve) => {
  if (blob == null) {
    return undefined
  }
  return Resizer.imageFileResizer(
    blob,
    512,
    512,
    'png',
    100,
    0,
    (value) => resolve(value as File),
    'file'
  )
})
