/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Icon, Image, Text } from "@aws-amplify/ui-react";
export default function UserItem(props) {
  const { person, overrides, ...rest } = props;
  return (
    <Flex
      gap="30px"
      direction="row"
      width="400px"
      height="120px"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="0px 30px 0px 30px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "UserItem")}
      {...rest}
    >
      <Image
        width="100px"
        height="100px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        border="2px SOLID rgba(21,48,77,1)"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
        borderRadius="50px"
        padding="0px 0px 0px 0px"
        objectFit="cover"
        {...getOverrideProps(overrides, "image")}
      ></Image>
      <Flex
        gap="15px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="flex-start"
        overflow="hidden"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "contentFrame")}
      >
        <Flex
          gap="3px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "companyGroup")}
        >
          <Text
            fontFamily="BIZ UDPGothic"
            fontSize="10px"
            fontWeight="700"
            color="rgba(85,85,85,1)"
            lineHeight="10px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="会社名"
            {...getOverrideProps(overrides, "companyLabel")}
          ></Text>
          <Icon
            width="210px"
            height="0px"
            viewBox={{ minX: 0, minY: 0, width: 210, height: 1 }}
            paths={[
              {
                d: "M0 0L210 0L210 -1L0 -1L0 0Z",
                stroke: "rgba(179,179,179,1)",
                fillRule: "nonzero",
                strokeWidth: 1,
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            {...getOverrideProps(overrides, "companyLine")}
          ></Icon>
          <Text
            fontFamily="BIZ UDPGothic"
            fontSize="15px"
            fontWeight="400"
            color="rgba(51,51,51,1)"
            lineHeight="15px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={person?.company}
            {...getOverrideProps(overrides, "company")}
          ></Text>
        </Flex>
        <Flex
          gap="3px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "nameGroup")}
        >
          <Text
            fontFamily="BIZ UDPGothic"
            fontSize="10px"
            fontWeight="700"
            color="rgba(85,85,85,1)"
            lineHeight="10px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="氏名"
            {...getOverrideProps(overrides, "nameLabel")}
          ></Text>
          <Icon
            width="210px"
            height="0px"
            viewBox={{ minX: 0, minY: 0, width: 210, height: 1 }}
            paths={[
              {
                d: "M0 0L210 0L210 -1L0 -1L0 0Z",
                stroke: "rgba(179,179,179,1)",
                fillRule: "nonzero",
                strokeWidth: 1,
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            {...getOverrideProps(overrides, "nameLine")}
          ></Icon>
          <Text
            fontFamily="BIZ UDPGothic"
            fontSize="15px"
            fontWeight="400"
            color="rgba(51,51,51,1)"
            lineHeight="15px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={person?.name}
            {...getOverrideProps(overrides, "name")}
          ></Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
