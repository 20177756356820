import { v4 as uuid } from 'uuid'
import React, { useEffect, useState } from 'react'
import { DataStore } from 'aws-amplify'
import { Person } from '../models'

const parser = new DOMParser()

interface Props {
  person: Person
  className?: string
  onClickDetails: () => void
}

export const BeerActionFrame = ({ person, className, onClickDetails }: Props): JSX.Element => {
  const [id] = useState(uuid())
  const [showDetail, setShowDetail] = useState(false)
  const [origin, setOrigin] = useState<Document>()
  const [viewModel, setViewModel] = useState<Document>()

  // fetch origin
  useEffect(() => {
    fetch('./atoms/BeerActionFrame.svg')
      .then(async result => await result.text())
      .then(text => {
        const origin = parser.parseFromString(text, 'application/xml')
        setOrigin(origin)
      })
      .catch(console.error)
  }, [])

  // process and update view model
  useEffect(() => {
    if (origin == null) {
      return
    }

    // clone and make it a new view
    const viewModel = origin.cloneNode(true) as Document

    // add event listeners
    const area = viewModel.getElementById('Area')
    area?.addEventListener('mouseenter', () => setShowDetail(true))
    area?.addEventListener('mouseleave', () => setShowDetail(false))
    area?.addEventListener('click', beerAction)

    const left = viewModel.getElementById('Left')
    left?.addEventListener('mouseenter', () => setShowDetail(true))
    left?.addEventListener('mouseleave', () => setShowDetail(false))

    const right = viewModel.getElementById('Right')
    right?.addEventListener('mouseenter', () => setShowDetail(true))
    right?.addEventListener('mouseleave', () => setShowDetail(false))
    right?.addEventListener('click', onClickDetails)

    if (person.getTogether ?? false) {
      viewModel.getElementById('BeerIconNo')?.setAttribute('display', 'none')
      viewModel.getElementById('BeerIconGo')?.setAttribute('class', 'pointer-events-none')
    } else {
      viewModel.getElementById('BeerIconGo')?.setAttribute('display', 'none')
      viewModel.getElementById('BeerIconNo')?.setAttribute('class', 'pointer-events-none')
    }

    if (!showDetail) {
      left?.setAttribute('display', 'none')
      right?.setAttribute('display', 'none')
    }

    setViewModel(viewModel)
  }, [origin, person, showDetail])

  // update views
  useEffect(() => {
    // clear
    const elements = document.getElementById(id)
    while (elements?.firstChild != null) {
      elements.removeChild(elements.lastChild as Node)
    }
    const svg = viewModel?.firstChild
    if (svg == null) {
      return
    }
    // set
    elements?.appendChild(svg)
  }, [viewModel])

  //  beer action
  const beerAction = (): void => {
    if (person == null) {
      throw new Error('person is null')
    }
    const value = person.getTogether !== true
    DataStore.save(Person.copyOf(person, updated => { updated.getTogether = value }))
      .catch(console.error)
  }

  return (
    <div id={id} className={className} />
  )
}
