import './ModalWindow.css'

import React from 'react'
import { Button } from '@aws-amplify/ui-react'

export interface ModalWindowProps {
  show?: boolean
  title?: string
  submit?: string
  children?: JSX.Element | JSX.Element[]
  onClose?: (() => void) | (() => Promise<void>)
  onSubmit?: (() => void) | (() => Promise<void>)
  onSuccess?: (() => void) | (() => Promise<void>)
  width?: string
  hiddenFooter?: boolean
}

export const ModalWindow = ({ title, submit = '確定する', children, onSubmit, onClose, show, width, hiddenFooter: footerDisabled }: ModalWindowProps): JSX.Element => {
  if (show === true) {
    return (
      <div className='modal-window-overlay' onClick={onClose}>
        <div className="modal-window-content" onClick={(e) => e.stopPropagation()} style={{ width }}>
          <div className="modal-window-header">
            <h5 className="modal-window-title">{title}</h5>
            <button type="button" className="btn-close modal-window-close" onClick={onClose} aria-label="Close"></button>
          </div>
          <hr className="text-secondary modal-window-line" />
          {
            children != null
              ? <>
                <div className="modal-window-body">
                  {children}
                </div>
              </>
              : <></>
          }
          {
            footerDisabled === true
              ? <></>
              : <>
                {/* <hr className="w-100 m-0 text-secondary" /> */}
                <div className="modal-window-hooter">
                  <Button type='submit' className='modal-window-submit' variation='primary' onClick={onSubmit}>{submit}</Button>
                </div>
              </>
          }
        </div>
      </div>
    )
  } else {
    return (<></>)
  }
}
