import React, { useContext, useState } from 'react'
import { ModalWindow } from '.'
import { Button, Flex, Grid, PasswordField, Text, View } from '@aws-amplify/ui-react'
import { Auth } from 'aws-amplify'
import { InProgressContext } from '../contexts'

interface Props {
  show: boolean
  onClose: () => void
}

export const ChangePasswordModal = ({ show, onClose }: Props): JSX.Element => {
  const setInProgress = useContext(InProgressContext)

  const [oldPassword, setPasswordNow] = useState('')
  const [newPassword, setPasswordNew] = useState('')
  const [retypedPassword, setPasswordCheck] = useState('')
  const [passwordError, setPasswordError] = useState<string>()

  if (setInProgress == null) {
    console.debug('context null')
    return <></>
  }

  return (
    <>
      <ModalWindow
        title={'パスワード変更'}
        onClose={onClose}
        hiddenFooter={true}
        width='460px'
        show={show}>
        <Grid
          height='340px'
          style={{
            alignItems: 'center'
          }}>
          {
            passwordError != null
              ? <Text color={'red'}>{passwordError}</Text>
              : <View height='24px'></View>
          }
          <PasswordField label='現在のパスワード' onChange={e => {
            setPasswordNow(e.currentTarget.value)
            setPasswordError(undefined)
          }} />
          <PasswordField label='新しいパスワード' onChange={e => {
            setPasswordNew(e.currentTarget.value)
            setPasswordError(undefined)
          }} />
          <PasswordField label='パスワードの確認' onChange={e => {
            setPasswordCheck(e.currentTarget.value)
            setPasswordError(undefined)
          }} />
          <Flex justifyContent={'flex-end'} margin='10px 0 0'>
            <Button
              isDisabled={oldPassword === '' || newPassword === '' || retypedPassword === '' || passwordError != null}
              onClick={() => {
                if (newPassword !== retypedPassword) {
                  setPasswordError('新しいパスワードと確認が一致しません。')
                  return
                }
                setInProgress(true)
                Auth.currentAuthenticatedUser().then(async (user) => {
                  return await Auth.changePassword(user, oldPassword, newPassword)
                }).then((data) => {
                  onClose()
                  setInProgress(false)
                }).catch((error: Error) => {
                  setInProgress(false)
                  switch (error.name) {
                    case 'NotAuthorizedException':
                      setPasswordError('パスワード認証に失敗しました。現在のパスワードをご確認ください。')
                      break
                    case 'LimitExceededException':
                      setPasswordError('試行回数の制限を超えました。しばらくしてから試してください。')
                      break
                    default:
                      setPasswordError(`${error.message}`)
                      console.error(error.name)
                      break
                  }
                })
              }}
              variation='primary'>変更する</Button>
          </Flex>
        </Grid>
      </ModalWindow>
    </>
  )
}
