import React from 'react'

interface _props<T,> {
  children?: JSX.Element | JSX.Element[]
  value?: T | null
}

type _context<T,> = React.Context<T | null>
type _provider<T,> = (props: _props<T>) => JSX.Element

export const createContext = <T,>(): [_context<T>, _provider<T>] => {
  const context = React.createContext<T | null>(null)
  const provider: _provider<T> = ({ children, value = null }) => {
    return (
      <context.Provider value={value}>
        {children}
      </context.Provider>
    )
  }
  return [context, provider]
}
