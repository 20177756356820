import './App.css'

import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { Auth, DataStore } from 'aws-amplify'
import { AuthEventData, AmplifyUser } from '@aws-amplify/ui'
import { View, Text } from '@aws-amplify/ui-react'
import { Whitelist, Person, Config } from './models'
import { FloorMap, ProfileRegistrant, Unapproved } from './pages'
import { ProfileEditor } from './pages/ProfileEditor/ProfileEditor'
import { whitelistJudgment } from './lib'
import { ModalWindow, InProgress } from './components'
import { ConfigDataModel } from './types'
import { ConfigProvider, InProgressProvider } from './contexts'

const CONFIG_SERVICE_NAME = 'service-name'
const CONFIG_SHOW_MACNICA_LOGO = 'show-macnica-logo'
const CONFIG_SHOW_CORPORATE_LOGO = 'show-corporate-logo'
const CONFIG_HEADER_VARIATION = 'header-variation'
const CONFIG_USER_ICON_VARIATION = 'user-icon-variation'
const CONFIG_FETCH_INTERVAL = 'fetch-interval'
const CONFIG_BEER = 'beer'
const CONFIG_BEER_COUNT = 'beer-count'

interface Props {
  signOut: ((data?: (AuthEventData | undefined)) => void)
  user: AmplifyUser
}

export const App = ({ user, signOut }: Props): JSX.Element => {
  if (typeof user.attributes === 'undefined') {
    throw new Error('App')
  }

  const email = user.attributes.email
  const navigate = useNavigate()
  const [inProgress, setInProgress] = useState(true)
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const [person, setPerson] = useState<Person | null>()
  const [persons, setPersons] = useState<Person[]>()
  const [config, setConfig] = useState<ConfigDataModel>()
  const [whitelist, setWhitelist] = useState<Whitelist[]>()

  const fetchConfig = (): void => {
    DataStore.query(Config)
      .then(configs => {
        const serviceName = configs.find(c => c.name === CONFIG_SERVICE_NAME)?.string ?? 'SEARCH'
        const showMacnicaLogo = configs.find(c => c.name === CONFIG_SHOW_MACNICA_LOGO)?.boolean ?? true
        const showCorporateLogo = configs.find(c => c.name === CONFIG_SHOW_CORPORATE_LOGO)?.boolean ?? true
        const headerVariation = configs.find(c => c.name === CONFIG_HEADER_VARIATION)?.string ?? 'white'
        const userIconVariation = configs.find(c => c.name === CONFIG_USER_ICON_VARIATION)?.int ?? 2
        const fetchInterval = configs.find(c => c.name === CONFIG_FETCH_INTERVAL)?.int ?? 5
        const beer = configs.find(c => c.name === CONFIG_BEER)?.boolean ?? false
        const beerCount = configs.find(c => c.name === CONFIG_BEER_COUNT)?.int ?? 3
        setConfig({
          serviceName,
          showMacnicaLogo,
          showCorporateLogo,
          headerVariation,
          userIconVariation,
          fetchInterval,
          beer,
          beerCount
        })
      })
      .catch(console.error)
  }

  const fetchPerson = (): void => {
    DataStore.query(Person)
      .then(setPersons)
      .catch(console.error)
  }

  const fetchWhitelist = (): void => {
    DataStore.query(Whitelist)
      .then(setWhitelist)
      .catch(console.error)
  }

  /** App */
  useEffect(() => {
    console.debug('effect []')

    // DataStore.start().catch(console.error)

    // パーソンを同期
    const personSubscription = DataStore.observeQuery(Person)
      .subscribe(snapshot => {
        console.debug('preson: ', snapshot)
        const { items, isSynced } = snapshot
        if (isSynced) {
          setPersons(items)
        } else {
          fetchPerson()
        }
      })

    // ホワイトリストを同期
    const whitelistSubscription = DataStore.observeQuery(Whitelist)
      .subscribe(snapshot => {
        console.debug('whitelist: ', snapshot)
        const { items, isSynced } = snapshot
        if (isSynced) {
          setWhitelist(items)
        } else {
          fetchWhitelist()
        }
      })

    const configSubscription = DataStore.observeQuery(Config)
      .subscribe(snapshot => {
        console.debug('config: ', snapshot)
        const { items, isSynced } = snapshot

        if (isSynced) {
          const serviceName = items.find(c => c.name === CONFIG_SERVICE_NAME)?.string ?? 'SEARCH'
          const showMacnicaLogo = items.find(c => c.name === CONFIG_SHOW_MACNICA_LOGO)?.boolean ?? true
          const showCorporateLogo = items.find(c => c.name === CONFIG_SHOW_CORPORATE_LOGO)?.boolean ?? true
          const headerVariation = items.find(c => c.name === CONFIG_HEADER_VARIATION)?.string ?? 'white'
          const userIconVariation = items.find(c => c.name === CONFIG_USER_ICON_VARIATION)?.int ?? 2
          const fetchInterval = items.find(c => c.name === CONFIG_FETCH_INTERVAL)?.int ?? 5
          const beer = items.find(c => c.name === CONFIG_BEER)?.boolean ?? false
          const beerCount = items.find(c => c.name === CONFIG_BEER_COUNT)?.int ?? 3
          setConfig({
            serviceName,
            showMacnicaLogo,
            showCorporateLogo,
            headerVariation,
            userIconVariation,
            fetchInterval,
            beer,
            beerCount
          })
        } else {
          fetchConfig()
        }
      })

    return () => {
      personSubscription.unsubscribe()
      whitelistSubscription.unsubscribe()
      configSubscription.unsubscribe()
    }
  }, [])

  /**
   * 本人情報セット
   */
  useEffect(() => {
    if (persons == null) {
      return
    }
    console.debug('effect persons: ', persons)
    const person = persons.find(p => p.email === email) ?? null
    setPerson(person)
  }, [persons])

  // personが設定されたとき
  // 認証判定を行い画面遷移
  useEffect(() => {
    if (config == null || whitelist == null) {
      return
    }
    const approved = whitelistJudgment(whitelist, person, user.attributes?.email)
    if (!approved) {
      navigate('/unapproved')
      return
    }
    if (person === null) {
      navigate('/profile-registrant')
    }
  }, [person, whitelist, config])

  return (
    <View className="App">
      <ConfigProvider value={config}>
        <InProgressProvider value={setInProgress}>
          <InProgress inProgess={inProgress} />
          <Routes>
            <Route path='/' element={<Navigate to='/floor-map' />} />
            <Route path='/unapproved' element={<Unapproved whitelist={whitelist} person={person} user={user} />} />
            <Route path='/floor-map' element={<FloorMap person={person} persons={persons} setShowLogoutModal={setShowLogoutModal} />} />
            <Route path='/profile-editor' element={<ProfileEditor person={person} />} />
            <Route path='/profile-registrant' element={
              <ProfileRegistrant
                person={person}
                email={user.attributes.email}
                onUpload={() => {
                  navigate('/floor-map')
                }}
              />} />
          </Routes>
          <ModalWindow
            title='ログアウト'
            onClose={() => setShowLogoutModal(false)}
            onSubmit={async () => await Auth.signOut().catch(console.error)}
            submit='ログアウト'
            show={showLogoutModal}>
            <Text margin={'15px 0'}>ログアウトしますか？</Text>
          </ModalWindow>
        </InProgressProvider>
      </ConfigProvider>
    </View>
  )
}
