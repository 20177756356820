import React, { useEffect, useState, useContext } from 'react'
import { v4 as uuid } from 'uuid'
import { ModalWindow, Profile } from '.'
import { Flex, ScrollView } from '@aws-amplify/ui-react'
import { UserItemCollection } from '../my-ui-components'
import { UserItemCollectionProps } from '../my-ui-components/UserItemCollection'
import { fetchProfileImage } from '../lib'
import { Person } from '../models'
import { ConfigContext } from '../contexts'

const parser = new DOMParser()

interface Props {
  className?: string
  onClose: () => void
  persons?: Person[] | null
}

export const BeerActionModal = ({ className, persons, onClose }: Props): JSX.Element => {
  const configContext = useContext(ConfigContext)
  const [descriptionId] = useState(uuid())
  const [images, setImages] = useState<Map<string, string>>()
  const [showProfile, setShowProfile] = useState(false)
  const [selectedPerson, setSelectedPerson] = useState<Person>()
  const [origin, setOrigin] = useState<Document>()
  const [viewModel, setViewModel] = useState<Document>()

  /** fetch origin */
  useEffect(() => {
    fetch('./atoms/BeerActionDescription.svg')
      .then(async result => await result.text())
      .then(text => {
        const origin = parser.parseFromString(text, 'application/xml')
        setOrigin(origin)
      })
      .catch(console.error)
  }, [])

  /** update view model */
  useEffect(() => {
    if (origin == null || configContext == null) {
      return
    }
    const viewModel = origin.cloneNode(true) as Document
    const text = viewModel.getElementById('text')
    if (text == null) {
      throw new Error('./atoms/BeerActionDescription.svg')
    }
    text.innerHTML = text.innerHTML.replace('3名', `${configContext.beerCount}名`)
    setViewModel(viewModel)
  }, [origin, configContext])

  /** update view */
  useEffect(() => {
    if (viewModel == null) {
      return
    }
    const elements = document.getElementById(descriptionId)
    if (elements == null) {
      throw new Error(`error: ${descriptionId}`)
    }
    while (elements?.firstChild != null) {
      elements.removeChild(elements.lastChild as Node)
    }
    const child = viewModel.firstChild
    if (child == null) {
      return
    }
    elements.appendChild(child)
  }, [viewModel])

  if (persons == null) {
    return <></>
  }
  const go = persons.length >= (configContext?.beerCount ?? 3)

  const fetchUserImages = (): void => {
    Promise.all(persons.map(async i => {
      let blob: string = ''
      try {
        blob = await fetchProfileImage(i)
      } catch (error) {
        console.error(error)
      }
      return {
        id: i.email,
        blob
      }
    }))
      .then(result => {
        setImages(new Map(result.map(r => [r.id, r.blob])))
      })
      .catch(console.error)
  }

  useEffect(() => {
    fetchUserImages()
  }, [])

  const onClick = (person: Person): void => {
    console.debug('onClick')
    setSelectedPerson(person)
    setShowProfile(true)
  }

  return (
    <>
      <ModalWindow
        title={'「CAFEで一杯！」' + (go ? '参加ユーザー' : '')}
        hiddenFooter={true}
        onClose={onClose}
        show={true} >
        <Flex margin='10px' direction={'column'}>
          <div id={descriptionId} />
          {
            go
              ? <ScrollView height={'260px'}>
                <UserItemCollection
                  items={persons}
                  overrideItems={overrideItems(onClick, images)}></UserItemCollection>
              </ScrollView>
              : <></>
          }
        </Flex>
        <ModalWindow
          show={showProfile}
          hiddenFooter={true}
          onClose={() => setShowProfile(false)}
          title='プロフィール'>
          <Profile person={selectedPerson}></Profile>
        </ModalWindow>
      </ModalWindow>
    </>
  )
}

/**
 * imageだけ書き換える
 */
const overrideItems = (onClick: (p: Person) => void, images?: Map<string, string>) => ({ item, index }: { item: Person, index: number }): UserItemCollectionProps => {
  return {
    overrides: {
      image: {
        onClick: () => onClick(item),
        src: images?.get(item.email)
      },
      UserItem: {
        backgroundColor: index % 2 === 0 ? '#fff' : '#F0F0F0',
        width: '100%'
      },
      companyLine: {
        // className: 'path-line'
      },
      nameLine: {
        // className: 'path-line'
      }
    }
  }
}
