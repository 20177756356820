/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { fetchByPath, validateField } from "./utils";
import { Config } from "../models";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import {
  Button,
  Flex,
  Grid,
  SwitchField,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";
export default function ConfigUpdateForm(props) {
  const {
    id,
    config,
    onSuccess,
    onError,
    onSubmit,
    onCancel,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    name: undefined,
    description: undefined,
    string: undefined,
    float: undefined,
    int: undefined,
    boolean: false,
  };
  const [name, setName] = React.useState(initialValues.name);
  const [description, setDescription] = React.useState(
    initialValues.description
  );
  const [string, setString] = React.useState(initialValues.string);
  const [float, setFloat] = React.useState(initialValues.float);
  const [int, setInt] = React.useState(initialValues.int);
  const [boolean, setBoolean] = React.useState(initialValues.boolean);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = { ...initialValues, ...configRecord };
    setName(cleanValues.name);
    setDescription(cleanValues.description);
    setString(cleanValues.string);
    setFloat(cleanValues.float);
    setInt(cleanValues.int);
    setBoolean(cleanValues.boolean);
    setErrors({});
  };
  const [configRecord, setConfigRecord] = React.useState(config);
  React.useEffect(() => {
    const queryData = async () => {
      const record = id ? await DataStore.query(Config, id) : config;
      setConfigRecord(record);
    };
    queryData();
  }, [id, config]);
  React.useEffect(resetStateValues, [configRecord]);
  const validations = {
    name: [{ type: "Required" }],
    description: [],
    string: [],
    float: [],
    int: [],
    boolean: [],
  };
  const runValidationTasks = async (fieldName, value) => {
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          name,
          description,
          string,
          float,
          int,
          boolean,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          await DataStore.save(
            Config.copyOf(configRecord, (updated) => {
              Object.assign(updated, modelFields);
            })
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...rest}
      {...getOverrideProps(overrides, "ConfigUpdateForm")}
    >
      <TextField
        label="Name"
        isRequired={true}
        isReadOnly={false}
        defaultValue={name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name: value,
              description,
              string,
              float,
              int,
              boolean,
            };
            const result = onChange(modelFields);
            value = result?.name ?? value;
          }
          if (errors.name?.hasError) {
            runValidationTasks("name", value);
          }
          setName(value);
        }}
        onBlur={() => runValidationTasks("name", name)}
        errorMessage={errors.name?.errorMessage}
        hasError={errors.name?.hasError}
        {...getOverrideProps(overrides, "name")}
      ></TextField>
      <TextAreaField
        label="Description"
        isRequired={false}
        isReadOnly={false}
        defaultValue={description}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              description: value,
              string,
              float,
              int,
              boolean,
            };
            const result = onChange(modelFields);
            value = result?.description ?? value;
          }
          if (errors.description?.hasError) {
            runValidationTasks("description", value);
          }
          setDescription(value);
        }}
        onBlur={() => runValidationTasks("description", description)}
        errorMessage={errors.description?.errorMessage}
        hasError={errors.description?.hasError}
        {...getOverrideProps(overrides, "description")}
      ></TextAreaField>
      <TextField
        label="String"
        isRequired={false}
        isReadOnly={false}
        defaultValue={string}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              description,
              string: value,
              float,
              int,
              boolean,
            };
            const result = onChange(modelFields);
            value = result?.string ?? value;
          }
          if (errors.string?.hasError) {
            runValidationTasks("string", value);
          }
          setString(value);
        }}
        onBlur={() => runValidationTasks("string", string)}
        errorMessage={errors.string?.errorMessage}
        hasError={errors.string?.hasError}
        {...getOverrideProps(overrides, "string")}
      ></TextField>
      <TextField
        label="Float"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        defaultValue={float}
        onChange={(e) => {
          let value = Number(e.target.value);
          if (isNaN(value)) {
            setErrors((errors) => ({
              ...errors,
              float: "Value must be a valid number",
            }));
            return;
          }
          if (onChange) {
            const modelFields = {
              name,
              description,
              string,
              float: value,
              int,
              boolean,
            };
            const result = onChange(modelFields);
            value = result?.float ?? value;
          }
          if (errors.float?.hasError) {
            runValidationTasks("float", value);
          }
          setFloat(value);
        }}
        onBlur={() => runValidationTasks("float", float)}
        errorMessage={errors.float?.errorMessage}
        hasError={errors.float?.hasError}
        {...getOverrideProps(overrides, "float")}
      ></TextField>
      <TextField
        label="Int"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        defaultValue={int}
        onChange={(e) => {
          let value = parseInt(e.target.value);
          if (isNaN(value)) {
            setErrors((errors) => ({
              ...errors,
              int: "Value must be a valid number",
            }));
            return;
          }
          if (onChange) {
            const modelFields = {
              name,
              description,
              string,
              float,
              int: value,
              boolean,
            };
            const result = onChange(modelFields);
            value = result?.int ?? value;
          }
          if (errors.int?.hasError) {
            runValidationTasks("int", value);
          }
          setInt(value);
        }}
        onBlur={() => runValidationTasks("int", int)}
        errorMessage={errors.int?.errorMessage}
        hasError={errors.int?.hasError}
        {...getOverrideProps(overrides, "int")}
      ></TextField>
      <SwitchField
        label="Boolean"
        defaultChecked={false}
        isDisabled={false}
        isChecked={boolean}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              name,
              description,
              string,
              float,
              int,
              boolean: value,
            };
            const result = onChange(modelFields);
            value = result?.boolean ?? value;
          }
          if (errors.boolean?.hasError) {
            runValidationTasks("boolean", value);
          }
          setBoolean(value);
        }}
        onBlur={() => runValidationTasks("boolean", boolean)}
        errorMessage={errors.boolean?.errorMessage}
        hasError={errors.boolean?.hasError}
        {...getOverrideProps(overrides, "boolean")}
      ></SwitchField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="更新"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
