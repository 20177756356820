/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { fetchByPath, validateField } from "./utils";
import { Person } from "../models";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import {
  Button,
  Divider,
  Flex,
  Grid,
  SwitchField,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";
export default function PersonUpdateForm(props) {
  const {
    id,
    person,
    onSuccess,
    onError,
    onSubmit,
    onCancel,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    email: undefined,
    assetCode: undefined,
    name: undefined,
    roma: undefined,
    company: undefined,
    position: undefined,
    shortCompany: undefined,
    nickname: undefined,
    shrotPosition: undefined,
    job: undefined,
    skills: undefined,
    wishes: undefined,
    favorites: undefined,
    word: undefined,
    crop: undefined,
    admin: false,
  };
  const [email, setEmail] = React.useState(initialValues.email);
  const [assetCode, setAssetCode] = React.useState(initialValues.assetCode);
  const [name, setName] = React.useState(initialValues.name);
  const [roma, setRoma] = React.useState(initialValues.roma);
  const [company, setCompany] = React.useState(initialValues.company);
  const [position, setPosition] = React.useState(initialValues.position);
  const [shortCompany, setShortCompany] = React.useState(
    initialValues.shortCompany
  );
  const [nickname, setNickname] = React.useState(initialValues.nickname);
  const [shrotPosition, setShrotPosition] = React.useState(
    initialValues.shrotPosition
  );
  const [job, setJob] = React.useState(initialValues.job);
  const [skills, setSkills] = React.useState(initialValues.skills);
  const [wishes, setWishes] = React.useState(initialValues.wishes);
  const [favorites, setFavorites] = React.useState(initialValues.favorites);
  const [word, setWord] = React.useState(initialValues.word);
  const [crop, setCrop] = React.useState(
    initialValues.crop ? JSON.stringify(initialValues.crop) : undefined
  );
  const [admin, setAdmin] = React.useState(initialValues.admin);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = { ...initialValues, ...personRecord };
    setEmail(cleanValues.email);
    setAssetCode(cleanValues.assetCode);
    setName(cleanValues.name);
    setRoma(cleanValues.roma);
    setCompany(cleanValues.company);
    setPosition(cleanValues.position);
    setShortCompany(cleanValues.shortCompany);
    setNickname(cleanValues.nickname);
    setShrotPosition(cleanValues.shrotPosition);
    setJob(cleanValues.job);
    setSkills(cleanValues.skills);
    setWishes(cleanValues.wishes);
    setFavorites(cleanValues.favorites);
    setWord(cleanValues.word);
    setCrop(
      typeof cleanValues.crop === "string"
        ? cleanValues.crop
        : JSON.stringify(cleanValues.crop)
    );
    setAdmin(cleanValues.admin);
    setErrors({});
  };
  const [personRecord, setPersonRecord] = React.useState(person);
  React.useEffect(() => {
    const queryData = async () => {
      const record = id ? await DataStore.query(Person, id) : person;
      setPersonRecord(record);
    };
    queryData();
  }, [id, person]);
  React.useEffect(resetStateValues, [personRecord]);
  const validations = {
    email: [{ type: "Required" }, { type: "Email" }],
    assetCode: [],
    name: [{ type: "Required" }],
    roma: [{ type: "Required" }],
    company: [{ type: "Required" }],
    position: [{ type: "Required" }],
    shortCompany: [{ type: "Required" }],
    nickname: [{ type: "Required" }],
    shrotPosition: [{ type: "Required" }],
    job: [{ type: "Required" }],
    skills: [{ type: "Required" }],
    wishes: [{ type: "Required" }],
    favorites: [{ type: "Required" }],
    word: [],
    crop: [{ type: "JSON" }],
    admin: [],
  };
  const runValidationTasks = async (fieldName, value) => {
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          email,
          assetCode,
          name,
          roma,
          company,
          position,
          shortCompany,
          nickname,
          shrotPosition,
          job,
          skills,
          wishes,
          favorites,
          word,
          crop,
          admin,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          await DataStore.save(
            Person.copyOf(personRecord, (updated) => {
              Object.assign(updated, modelFields);
            })
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...rest}
      {...getOverrideProps(overrides, "PersonUpdateForm")}
    >
      <TextField
        label="Email"
        isRequired={true}
        isReadOnly={false}
        defaultValue={email}
        onInput={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              email: value,
              assetCode,
              name,
              roma,
              company,
              position,
              shortCompany,
              nickname,
              shrotPosition,
              job,
              skills,
              wishes,
              favorites,
              word,
              crop,
              admin,
            };
            const result = onChange(modelFields);
            value = result?.email ?? value;
          }
          if (errors.email?.hasError) {
            runValidationTasks("email", value);
          }
          setEmail(value);
        }}
        onBlur={() => runValidationTasks("email", email)}
        errorMessage={errors.email?.errorMessage}
        hasError={errors.email?.hasError}
        {...getOverrideProps(overrides, "email")}
      ></TextField>
      <TextField
        label="Asset code"
        isRequired={false}
        isReadOnly={false}
        placeholder="00XXXXXX"
        defaultValue={assetCode}
        onInput={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              email,
              assetCode: value,
              name,
              roma,
              company,
              position,
              shortCompany,
              nickname,
              shrotPosition,
              job,
              skills,
              wishes,
              favorites,
              word,
              crop,
              admin,
            };
            const result = onChange(modelFields);
            value = result?.assetCode ?? value;
          }
          if (errors.assetCode?.hasError) {
            runValidationTasks("assetCode", value);
          }
          setAssetCode(value);
        }}
        onBlur={() => runValidationTasks("assetCode", assetCode)}
        errorMessage={errors.assetCode?.errorMessage}
        hasError={errors.assetCode?.hasError}
        {...getOverrideProps(overrides, "assetCode")}
      ></TextField>
      <TextField
        label="氏名"
        isRequired={true}
        isReadOnly={false}
        defaultValue={name}
        onInput={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              email,
              assetCode,
              name: value,
              roma,
              company,
              position,
              shortCompany,
              nickname,
              shrotPosition,
              job,
              skills,
              wishes,
              favorites,
              word,
              crop,
              admin,
            };
            const result = onChange(modelFields);
            value = result?.name ?? value;
          }
          if (errors.name?.hasError) {
            runValidationTasks("name", value);
          }
          setName(value);
        }}
        onBlur={() => runValidationTasks("name", name)}
        errorMessage={errors.name?.errorMessage}
        hasError={errors.name?.hasError}
        {...getOverrideProps(overrides, "name")}
      ></TextField>
      <TextField
        label="氏名（ローマ字）"
        isRequired={true}
        isReadOnly={false}
        defaultValue={roma}
        onInput={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              email,
              assetCode,
              name,
              roma: value,
              company,
              position,
              shortCompany,
              nickname,
              shrotPosition,
              job,
              skills,
              wishes,
              favorites,
              word,
              crop,
              admin,
            };
            const result = onChange(modelFields);
            value = result?.roma ?? value;
          }
          if (errors.roma?.hasError) {
            runValidationTasks("roma", value);
          }
          setRoma(value);
        }}
        onBlur={() => runValidationTasks("roma", roma)}
        errorMessage={errors.roma?.errorMessage}
        hasError={errors.roma?.hasError}
        {...getOverrideProps(overrides, "roma")}
      ></TextField>
      <TextField
        label="会社名（正式名称）"
        isRequired={true}
        isReadOnly={false}
        defaultValue={company}
        onInput={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              email,
              assetCode,
              name,
              roma,
              company: value,
              position,
              shortCompany,
              nickname,
              shrotPosition,
              job,
              skills,
              wishes,
              favorites,
              word,
              crop,
              admin,
            };
            const result = onChange(modelFields);
            value = result?.company ?? value;
          }
          if (errors.company?.hasError) {
            runValidationTasks("company", value);
          }
          setCompany(value);
        }}
        onBlur={() => runValidationTasks("company", company)}
        errorMessage={errors.company?.errorMessage}
        hasError={errors.company?.hasError}
        {...getOverrideProps(overrides, "company")}
      ></TextField>
      <TextField
        label="部署名"
        isRequired={true}
        isReadOnly={false}
        defaultValue={position}
        onInput={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              email,
              assetCode,
              name,
              roma,
              company,
              position: value,
              shortCompany,
              nickname,
              shrotPosition,
              job,
              skills,
              wishes,
              favorites,
              word,
              crop,
              admin,
            };
            const result = onChange(modelFields);
            value = result?.position ?? value;
          }
          if (errors.position?.hasError) {
            runValidationTasks("position", value);
          }
          setPosition(value);
        }}
        onBlur={() => runValidationTasks("position", position)}
        errorMessage={errors.position?.errorMessage}
        hasError={errors.position?.hasError}
        {...getOverrideProps(overrides, "position")}
      ></TextField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Divider>
      <TextField
        label="会社名（略称）"
        descriptiveText="ユーザーアイコンに表示される会社名を８文字までで入力してください"
        isRequired={true}
        isReadOnly={false}
        defaultValue={shortCompany}
        onInput={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              email,
              assetCode,
              name,
              roma,
              company,
              position,
              shortCompany: value,
              nickname,
              shrotPosition,
              job,
              skills,
              wishes,
              favorites,
              word,
              crop,
              admin,
            };
            const result = onChange(modelFields);
            value = result?.shortCompany ?? value;
          }
          if (errors.shortCompany?.hasError) {
            runValidationTasks("shortCompany", value);
          }
          setShortCompany(value);
        }}
        onBlur={() => runValidationTasks("shortCompany", shortCompany)}
        errorMessage={errors.shortCompany?.errorMessage}
        hasError={errors.shortCompany?.hasError}
        {...getOverrideProps(overrides, "shortCompany")}
      ></TextField>
      <TextField
        label="ニックネーム"
        descriptiveText="ユーザーアイコンに表示されるニックネームを８文字までで入力してください"
        isRequired={true}
        isReadOnly={false}
        defaultValue={nickname}
        onInput={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              email,
              assetCode,
              name,
              roma,
              company,
              position,
              shortCompany,
              nickname: value,
              shrotPosition,
              job,
              skills,
              wishes,
              favorites,
              word,
              crop,
              admin,
            };
            const result = onChange(modelFields);
            value = result?.nickname ?? value;
          }
          if (errors.nickname?.hasError) {
            runValidationTasks("nickname", value);
          }
          setNickname(value);
        }}
        onBlur={() => runValidationTasks("nickname", nickname)}
        errorMessage={errors.nickname?.errorMessage}
        hasError={errors.nickname?.hasError}
        {...getOverrideProps(overrides, "nickname")}
      ></TextField>
      <TextField
        label="部署名（略称）"
        descriptiveText="ユーザーアイコンに表示される部署名を８文字までで入力してください"
        isRequired={true}
        isReadOnly={false}
        defaultValue={shrotPosition}
        onInput={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              email,
              assetCode,
              name,
              roma,
              company,
              position,
              shortCompany,
              nickname,
              shrotPosition: value,
              job,
              skills,
              wishes,
              favorites,
              word,
              crop,
              admin,
            };
            const result = onChange(modelFields);
            value = result?.shrotPosition ?? value;
          }
          if (errors.shrotPosition?.hasError) {
            runValidationTasks("shrotPosition", value);
          }
          setShrotPosition(value);
        }}
        onBlur={() => runValidationTasks("shrotPosition", shrotPosition)}
        errorMessage={errors.shrotPosition?.errorMessage}
        hasError={errors.shrotPosition?.hasError}
        {...getOverrideProps(overrides, "shrotPosition")}
      ></TextField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Divider>
      <Text
        children="プロフィールに表示する情報を入力してください"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Text>
      <TextAreaField
        label="仕事内容 / 関心のあるテーマ"
        descriptiveText=""
        isRequired={true}
        isReadOnly={false}
        defaultValue={job}
        onInput={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              email,
              assetCode,
              name,
              roma,
              company,
              position,
              shortCompany,
              nickname,
              shrotPosition,
              job: value,
              skills,
              wishes,
              favorites,
              word,
              crop,
              admin,
            };
            const result = onChange(modelFields);
            value = result?.job ?? value;
          }
          if (errors.job?.hasError) {
            runValidationTasks("job", value);
          }
          setJob(value);
        }}
        onBlur={() => runValidationTasks("job", job)}
        errorMessage={errors.job?.errorMessage}
        hasError={errors.job?.hasError}
        {...getOverrideProps(overrides, "job")}
      ></TextAreaField>
      <TextAreaField
        label="スキルや資格"
        descriptiveText=""
        isRequired={true}
        isReadOnly={false}
        defaultValue={skills}
        onInput={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              email,
              assetCode,
              name,
              roma,
              company,
              position,
              shortCompany,
              nickname,
              shrotPosition,
              job,
              skills: value,
              wishes,
              favorites,
              word,
              crop,
              admin,
            };
            const result = onChange(modelFields);
            value = result?.skills ?? value;
          }
          if (errors.skills?.hasError) {
            runValidationTasks("skills", value);
          }
          setSkills(value);
        }}
        onBlur={() => runValidationTasks("skills", skills)}
        errorMessage={errors.skills?.errorMessage}
        hasError={errors.skills?.hasError}
        {...getOverrideProps(overrides, "skills")}
      ></TextAreaField>
      <TextAreaField
        label="欲しい情報"
        descriptiveText=""
        isRequired={true}
        isReadOnly={false}
        defaultValue={wishes}
        onInput={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              email,
              assetCode,
              name,
              roma,
              company,
              position,
              shortCompany,
              nickname,
              shrotPosition,
              job,
              skills,
              wishes: value,
              favorites,
              word,
              crop,
              admin,
            };
            const result = onChange(modelFields);
            value = result?.wishes ?? value;
          }
          if (errors.wishes?.hasError) {
            runValidationTasks("wishes", value);
          }
          setWishes(value);
        }}
        onBlur={() => runValidationTasks("wishes", wishes)}
        errorMessage={errors.wishes?.errorMessage}
        hasError={errors.wishes?.hasError}
        {...getOverrideProps(overrides, "wishes")}
      ></TextAreaField>
      <TextAreaField
        label="趣味・好きなこと"
        descriptiveText=""
        isRequired={true}
        isReadOnly={false}
        defaultValue={favorites}
        onInput={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              email,
              assetCode,
              name,
              roma,
              company,
              position,
              shortCompany,
              nickname,
              shrotPosition,
              job,
              skills,
              wishes,
              favorites: value,
              word,
              crop,
              admin,
            };
            const result = onChange(modelFields);
            value = result?.favorites ?? value;
          }
          if (errors.favorites?.hasError) {
            runValidationTasks("favorites", value);
          }
          setFavorites(value);
        }}
        onBlur={() => runValidationTasks("favorites", favorites)}
        errorMessage={errors.favorites?.errorMessage}
        hasError={errors.favorites?.hasError}
        {...getOverrideProps(overrides, "favorites")}
      ></TextAreaField>
      <TextField
        label="一言(任意)"
        isRequired={false}
        isReadOnly={false}
        defaultValue={word}
        onInput={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              email,
              assetCode,
              name,
              roma,
              company,
              position,
              shortCompany,
              nickname,
              shrotPosition,
              job,
              skills,
              wishes,
              favorites,
              word: value,
              crop,
              admin,
            };
            const result = onChange(modelFields);
            value = result?.word ?? value;
          }
          if (errors.word?.hasError) {
            runValidationTasks("word", value);
          }
          setWord(value);
        }}
        onBlur={() => runValidationTasks("word", word)}
        errorMessage={errors.word?.errorMessage}
        hasError={errors.word?.hasError}
        {...getOverrideProps(overrides, "word")}
      ></TextField>
      <TextAreaField
        label="Crop"
        isRequired={false}
        isReadOnly={false}
        defaultValue={crop}
        onInput={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              email,
              assetCode,
              name,
              roma,
              company,
              position,
              shortCompany,
              nickname,
              shrotPosition,
              job,
              skills,
              wishes,
              favorites,
              word,
              crop: value,
              admin,
            };
            const result = onChange(modelFields);
            value = result?.crop ?? value;
          }
          if (errors.crop?.hasError) {
            runValidationTasks("crop", value);
          }
          setCrop(value);
        }}
        onBlur={() => runValidationTasks("crop", crop)}
        errorMessage={errors.crop?.errorMessage}
        hasError={errors.crop?.hasError}
        {...getOverrideProps(overrides, "crop")}
      ></TextAreaField>
      <SwitchField
        label="Admin"
        defaultChecked={false}
        isDisabled={false}
        isChecked={admin}
        onInput={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              email,
              assetCode,
              name,
              roma,
              company,
              position,
              shortCompany,
              nickname,
              shrotPosition,
              job,
              skills,
              wishes,
              favorites,
              word,
              crop,
              admin: value,
            };
            const result = onChange(modelFields);
            value = result?.admin ?? value;
          }
          if (errors.admin?.hasError) {
            runValidationTasks("admin", value);
          }
          setAdmin(value);
        }}
        onBlur={() => runValidationTasks("admin", admin)}
        errorMessage={errors.admin?.errorMessage}
        hasError={errors.admin?.hasError}
        {...getOverrideProps(overrides, "admin")}
      ></SwitchField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="更新"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
