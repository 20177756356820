import './Unapproved.css'

import React, { useContext, useEffect, useState } from 'react'
import { Text, Divider, Button } from '@aws-amplify/ui-react'
import { Auth } from 'aws-amplify'
import { AmplifyUser } from '@aws-amplify/ui'
import { Person, Whitelist } from '../../models'
import { whitelistJudgment } from '../../lib'
import { useNavigate } from 'react-router-dom'
import { InProgressContext } from '../../contexts'

interface Props {
  user: AmplifyUser
  person?: Person | null
  whitelist?: Whitelist[]
}

/**
 * 未承認ユーザー
 */
export const Unapproved = ({ user, person, whitelist }: Props): JSX.Element => {
  const navigate = useNavigate()
  const setInProgress = useContext(InProgressContext)
  const [wait, setWait] = useState(true)

  const signOut = (): void => {
    Auth.signOut().catch(console.error)
  }

  useEffect(() => {
    //  一秒後に表示
    const timeout = setTimeout(() => {
      setWait(false)
      setInProgress?.(false)
    }, 1000)
    return () => clearTimeout(timeout)
  }, [])

  /**
   * 認証済みであれば、フロアマップ画面に遷移する
   */
  useEffect(() => {
    if (whitelist == null) {
      return
    }
    const approved = whitelistJudgment(whitelist, person, user.attributes?.email)
    if (approved) {
      setInProgress?.(true)
      navigate('/')
    }
  }, [whitelist, person, user])

  return (
    <>
      {
        wait
          ? <></>
          : <div className='unapproved-page'>
            <h1>未承認ユーザー</h1>
            <p>{user?.attributes?.email} は承認されていません。</p>
            <p>管理者にお問い合わせください。</p>
            <p>もし、認証されている場合はページを更新してください。</p>
            <Button onClick={signOut}>サインアウト</Button>
            <div className="unapproved-hooter">
              <Divider orientation="horizontal" />
              <Text color={'#767C81'} fontSize={'12px'}>&copy; 2023 Macnica, Inc.</Text>
            </div>
          </div>

      }
    </>
  )
}
